export const apiUrls = {
  // * Auth
  EMAIL_RESEND: 'auth/emailReSend',
  CHECK_UNIQUE_DATA: '/auth/checkuser',

  //* OTHER
  REGISTER: '/create/userinfo',
  OTP_VERIFY: '/verify-otp',
  RESEND_OTP: '/resend-otp',
  COMPANY_TYPE: '/companyType',
};
