import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import { EmailVerificationPage } from './modules/auth/register/components';
import {
  CompanyRegistration,
  ContactPage,
  Authentication,
  SignUpPage,
} from './pages';
import ProtectedRouter from './components/Router/ProtectedRouter';
import AuthRouter from './components/Router/AuthRouter';
import CompanyInfo from './pages/Pageinfo';
import { StandardSwitchPage, StandardDashboard } from './standardUIpage';
import { isMobile } from 'react-device-detect';

// Auth module components
// const SignUp = lazy(() => import('./pages/signUpPage/SignUpPage'));

export default function Router() {
  return (
    // TODO : set Fallback ui
    <Suspense fallback={<></>}>
      <BrowserRouter>
        <Routes>
          {isMobile ? (
            <Route path="/" element={<Navigate to="/register" />} />
          ) : (
            <Route path="/" element={<Navigate to="/company_registration" />} />
          )}
          <Route path="/pageinfo" element={<CompanyInfo />} />
          <Route
            path="/signup"
            element={
              <AuthRouter>
                <SignUpPage />
              </AuthRouter>
            }
          />
          <Route
            path="/confirmation"
            element={
              <AuthRouter>
                <EmailVerificationPage />
              </AuthRouter>
            }
          />
          <Route
            path="/login"
            element={
              <AuthRouter>
                <LoginPage />
              </AuthRouter>
            }
          />
          <Route
            path="/contactus"
            element={
              <AuthRouter>
                <ContactPage />
              </AuthRouter>
            }
          />
          <Route path="/register" element={<Authentication />} />
          <Route path="/" element={<ProtectedRouter />}>
            <Route
              path="/company_registration"
              element={<CompanyRegistration />}
            />
          </Route>
          <Route path="/register" element={<Authentication />} />

          {/* UI Standard */}
          <Route path="/standard" element={<StandardDashboard />}>
            <Route index element={<StandardSwitchPage />} />
            <Route path="switch" element={<StandardSwitchPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}
