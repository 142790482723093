import { ChangeEvent, useState } from 'react';
import * as Yup from 'yup';

const useContactUs = () => {
  const [showMessageModal, setShowMessageModal] = useState(false);

  const initialValues = {
    firstName: '',
    lastName: '',
    userName: '',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    userName: Yup.string().required('User Name is required'),
  });

  const handleOnSubmit = () => {
    setShowMessageModal(true);
  };
  return {
    initialValues,
    validationSchema,
    handleOnSubmit,
    showMessageModal,
  };
};
export default useContactUs;
