import * as React from 'react';
import Router from './router';

export default function App() {
  return (
    <div>
      <Router />
    </div>
  );
}
