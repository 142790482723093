import React, { HTMLAttributes } from 'react';
import images from '../../../assets/images';
import clsx from 'clsx';

const Footer: React.FC<HTMLAttributes<any>> = ({
  className,
  style,
  ...rest
}) => {
  const list = [
    { label: 'Privacy Policy' },
    { label: 'Terms of Service' },
    { label: 'Contact Us' },
  ];

  return (
    <footer
      className="font-size sticky top-[100vh] border-0 border-t-4 border-solid border-buddha-love-900 py-2 xl:h-[63.5px]"
      style={{
        boxShadow: 'inset 0px 3px 6px #00000029, 0px 3px 6px #00000029',
        ...style,
      }}
      {...rest}
    >
      <div
        className={clsx(
          'h-full items-center justify-between px-[10px] text-center sm:px-[50px] lg:flex',
          className,
        )}
      >
        <p className="custom-display m-0 items-center justify-center text-blue-gray-100 sm:flex sm:gap-2">
          <span className="mt-2">Powered by</span>
          <img src={images.bottomLogo} alt="cosLogo" />
          <span className="mt-2">
            Construction Ops Software Co - All Rights Reserved ©
          </span>
        </p>

        <div className="mt-2 text-blue-gray-100">
          {list.map((item, index) => (
            <span key={`${index}-footer-list-item`}>
              {item.label}{' '}
              {index + 1 !== list.length && (
                <span> &nbsp;&nbsp;|&nbsp;&nbsp; </span>
              )}
            </span>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
