import { Typography, TypographyProps } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

interface CosTypoGraphyProps extends TypographyProps {
  fontFamily?: 'controller';
}

const CosTypoGraphy: React.FC<CosTypoGraphyProps> = ({
  variant = 'h6',
  className,
  fontFamily,
  ...rest
}) => {
  return (
    <Typography
      variant={variant}
      className={clsx(
        'm-0 font-semibold text-dark-blue-900',
        {
          'font-controller': fontFamily === 'controller',
        },
        className,
      )}
      {...rest}
    />
  );
};

export default CosTypoGraphy;
