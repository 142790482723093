import React, { ReactNode } from 'react';

interface ErrorMessageProps {
  message?: string;
  children?: ReactNode;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message, children }) => {
  return (
    <p
      className="mb-0 text-left"
      style={{ color: '#EC554E', fontSize: '13px' }}
    >
      {message ? message : children}
    </p>
  );
};

export default ErrorMessage;
