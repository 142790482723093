import { CustomError } from './interface';
import { tabbable } from 'tabbable';

export const validateUserPassword = (value: string): string => {
  const errors: CustomError[] = [
    {
      message: 'Enter between 8-16 characters.',
      isValid: 8 <= value.length && 16 >= value.length,
    },

    {
      message: 'Include at least one uppercase letter.',
      isValid: /(?=.*[A-Z])/.test(value),
    },
    {
      message: 'Include at least one special character.',
      isValid: /(?=.*[!@#$%^&*])/.test(value),
    },
    {
      message: 'Include at least one number.',
      isValid: /(?=.*\d)/.test(value),
    },
  ];
  return JSON.stringify(errors);
};

export const validateUserEmail = (value: string): string => {
  const errors: CustomError[] = [
    {
      message: 'Email Required.',
      isValid: /\S+@\S+\.\S+/.test(value), // Basic email format check
    },
  ];
  return JSON.stringify(errors);
};

export const validatePassword = (value: string): string => {
  const errors: CustomError[] = [
    {
      message: 'Password Required.',
      isValid: [
        value.length >= 8,
        /[A-Z]/.test(value),
        /[!@#$%^&*]/.test(value),
      ].every(condition => condition),
    },
  ];

  return JSON.stringify(errors);
};
const validateUserName = (username: string): boolean => {
  // Define your username pattern using a regular expression
  const usernamePattern = /^[a-zA-Z0-9_]{3,20}$/;

  // Test if the provided username matches the pattern
  return usernamePattern.test(username);
};

export const validateEmailOrUsername = (value: string): string => {
  let isEmailValid = false;
  let isUsernameValid = false;

  if (/\S+@\S+\.\S+/.test(value)) {
    isEmailValid = true;
  } else {
    isUsernameValid = validateUserName(value);
  }

  const errors: CustomError[] = [
    {
      message: isEmailValid
        ? 'Email is Valid'
        : isUsernameValid
          ? 'Username is Valid'
          : 'Username is Required',
      // Invalid Email or Username
      isValid: isEmailValid || isUsernameValid,
    },
  ];

  return JSON.stringify(errors);
};

// * For Move cursor to next felid
export const moveTabToNextField = (): void => {
  // * Get all focusable felid
  const focusable = tabbable(document as any);

  //* Filter out elements with data-testid equal to 'sentinelStart' or 'sentinelEnd' which use for focus-trap
  const filteredFocusable = focusable.filter(element => {
    const testId = element.getAttribute('data-testid');
    return testId !== 'sentinelStart' && testId !== 'sentinelEnd';
  });
  const currentElement: any = document.activeElement;
  const currentElementIndex = filteredFocusable.findIndex(
    element => element === currentElement,
  );

  // * Move Next focus to next focusable field
  if (filteredFocusable.length - 1 === currentElementIndex) {
    filteredFocusable[0]?.focus();
  } else {
    filteredFocusable[currentElementIndex + 1]?.focus();
  }
};
