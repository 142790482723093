import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { constant } from '../../../../libs/constant';
import clsx from 'clsx';
import CosSearchInput from '../../../../components/formikForms/ui/CosSearchInput';
import AdminProfile from './AdminProfile';

// TODO : manage type
const Navigator = ({ className }: any) => {
  const searchInputProps = {};

  return (
    <Box className={clsx(className)} component="main">
      <Box
        component="section"
        className="flex h-[59px] items-center justify-between pl-5 pr-2"
      >
        <CosSearchInput placeholder="Search SchedulrOps" />
        <Box component="div">
          <AdminProfile />
        </Box>
      </Box>

      <Divider className="h-[1px] bg-white opacity-30" />

      {/* Navigator */}
      <Box
        component="section"
        className="flex h-[40px] items-center justify-between pl-5 pr-2"
      >
        <Box component="div" className="flex-gow">
          <Typography
            component="h5"
            className="-tracking-0.45 text-25/34 font-semibold"
          >
            My Company
          </Typography>
        </Box>
        <CosSearchInput placeholder="Search Company" className="self-end" />
      </Box>
    </Box>
  );
};

export default Navigator;
