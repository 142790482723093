import { Typography } from '@mui/material';
import Masterlayout from '../layout/masterlayout/Masterlayout';
import CompanyRegistrationFrom from '../modules/companyRegistration/components/CompanyRegistrationFrom';

export default function MiniDrawer() {
  return (
    <Masterlayout>
      <CompanyRegistrationFrom />
    </Masterlayout>
  );
}
