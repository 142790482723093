import { Box, Toolbar, Typography } from '@mui/material';
import AppBar from './ui/AppBar';
import AppBarLeftSideBarHeader from './ui/AppBarLeftSideBarHeader';
import Navigator from './ui/Navigator';

const Navbar = ({ handleDrawerOpen, open }: any) => {
  return (
    <AppBar position="fixed" open={open}>
      <Toolbar className="p-0">
        {/* // TODO : ADD logo here */}
        <AppBarLeftSideBarHeader className="bg-white"></AppBarLeftSideBarHeader>
        <Navigator className="flex-grow" />
      </Toolbar>
    </AppBar>
  );
};
export default Navbar;
