import React, { useState } from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';
import { CosAutoComplete } from './ui';
import { ChipTypeMap } from '@mui/material';
import { CosAutoCompleteProps } from './ui/CosAutoComplete';
import clsx from 'clsx';
import { CosFormErrorAlert } from '..';
import { CosAlertProps } from '../alert/CosFormErrorAlert';

export interface Props<
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
> extends CosAutoCompleteProps<
    Value,
    Multiple,
    DisableClearable,
    FreeSolo,
    ChipComponent
  > {
  name: string;
  hideDefaultError?: boolean;
  alertProps?: Omit<CosAlertProps, 'children'>;
}

const FormAutoComplete = <
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>({
  name,
  className,
  inputProps,
  hideDefaultError,
  onBlur,
  onFocus,
  onChange,
  alertProps,
  ...rest
}: Props<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>) => {
  const { handleChange, setFieldValue, handleBlur, errors, setFieldTouched } =
    useFormikContext();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <div>
          <CosAutoComplete
            className={clsx('!mb-0', className)}
            onChange={(event, option: any, reason, details) => {
              setFieldValue(name, option?.value || '');
              onChange && onChange(event, option, reason, details);
            }}
            onFocus={event => {
              setIsFocused(true);
              onFocus && onFocus(event);
            }}
            onBlur={event => {
              handleBlur(event);
              setFieldTouched(name, true);
              setIsFocused(false);
              onBlur && onBlur(event);
            }}
            name={name}
            {...rest}
          />
          {!hideDefaultError &&
            meta.touched &&
            meta.error &&
            meta.error !== ' ' &&
            !isFocused && (
              <CosFormErrorAlert
                severity="error"
                className="mt-2"
                icon={true}
                {...alertProps}
              >
                {meta.error}
              </CosFormErrorAlert>
            )}
        </div>
      )}
    </Field>
  );
};

export default FormAutoComplete;
