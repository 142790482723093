import * as Yup from 'yup';
import { CustomError } from '../../../../libs/interface';
import { ChangeEvent, useState } from 'react';

import Rest from '../../../../Rest';
import { FormikProps, FormikValues } from 'formik';
import { apiUrls } from '../../../../apiUrl';
import { checkIsValidEmail } from '../../../../libs/utils';
interface InitialValues {
  companyName: string;
  firstName: string;
  int: string;
  lastName: string;
  email: string;
  companyType: string;
}

export type FieldName = keyof InitialValues;
type StateTypeForValue = Partial<Record<FieldName, string>>;

interface UniqueDataPayload {
  email: string;
  companyName: string;
}

const useRegister = () => {
  const tileCaseField = ['companyName', 'firstName', 'lastName'];

  const initialValues: InitialValues = {
    companyName: '',
    firstName: '',
    int: '',
    lastName: '',
    email: '',
    companyType: '',
  };

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().min(6, '').required('Company Name is required'),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    int: Yup.string(),
    email: Yup.string()
      // .email('Email Address is not Valid')
      .test('is-valid', 'Email Address is Invalid', value =>
        checkIsValidEmail(value || ''),
      )
      .required('Email Address is required'),
    companyType: Yup.string().required('Company Name is required'),
  });

  const [customFieldError, setCustomFieldError] = useState<StateTypeForValue>(
    {},
  );

  // * Set custom error for form
  const setError = (name: FieldName, value: string) => {
    setCustomFieldError(preValue => ({
      ...preValue,
      [name]: value,
    }));
  };

  // * Check is given data is available or not
  const checkIsUniqueName = async (payload: Partial<UniqueDataPayload>) => {
    let data: any = '';
    await Rest.post(apiUrls.CHECK_UNIQUE_DATA, { ...payload })
      .then(response => {
        data = response;
      })
      .catch(error => {
        console.error(error);
      });
    return data;
  };

  // * on form field change
  const handleOnChange = async (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formik: FormikProps<InitialValues>,
    type?: 'selectBox',
  ) => {
    // const { name, value }: { name: string; value: string } = event.target;
    let name = '';
    let value = '';
    const { setFieldValue, touched } = formik;

    if (type === 'selectBox') {
      const element = event.target;
      name = element.getAttribute('name') as string;
      value = element.getAttribute('value') as string;
    } else {
      const {
        name: elementName,
        value: elementValue,
      }: { name: string; value: string } = event.target;
      name = elementName;
      value = elementValue;
    }

    if (tileCaseField.includes(name)) {
      await setFieldValue(name, value?.toCapitalize());
    } else {
      await setFieldValue(name, value);
    }

    switch (name as FieldName) {
      case 'companyName':
        if (touched.companyName) {
          +value.length < 6 &&
            setError(
              'companyName',
              JSON.stringify([
                {
                  isValid: false,
                  message: 'Enter at least 6 characters',
                },
              ]),
            );

          getCustomErrorMessageObj(
            customFieldError.companyName || '',
            'Enter at least 6 characters',
          )?.isValid === false &&
            setError(
              'companyName',
              JSON.stringify([
                {
                  isValid: +value.length > 6,
                  message: 'Enter at least 6 characters',
                },
              ]),
            );
          if (value === '') {
            setRequiredErrorMessage('companyName', 'Company Name', false);
          }
        }

        break;
      case 'firstName':
        if (touched.firstName) {
          getCustomErrorMessageObj(
            customFieldError.firstName || '',
            'First Name Required',
          )?.isValid === false &&
            setRequiredErrorMessage('firstName', 'First Name', true);
          if (value === '') {
            setRequiredErrorMessage('firstName', 'First Name', false);
          }
        }
        break;
      case 'lastName':
        if (touched.lastName) {
          getCustomErrorMessageObj(
            customFieldError.lastName || '',
            'Last Name Required',
          )?.isValid === false &&
            setRequiredErrorMessage('lastName', 'Last Name', true);
          if (value === '') {
            setRequiredErrorMessage('lastName', 'Last Name', false);
          }
        }
        break;
      case 'email':
        if (touched.email) {
          const isValidEmail = checkIsValidEmail(value);

          !isValidEmail &&
            setError(
              'email',
              JSON.stringify([
                {
                  isValid: false,
                  message: 'Email Address is Invalid',
                },
              ]),
            );

          getCustomErrorMessageObj(
            customFieldError.email || '',
            'Email Address is Invalid',
          )?.isValid === false &&
            setError(
              'email',
              JSON.stringify([
                {
                  isValid: isValidEmail,
                  message: isValidEmail
                    ? 'Email Address is Valid'
                    : 'Email Address is Invalid',
                },
              ]),
            );

          if (value === '') {
            setRequiredErrorMessage('email', 'Email Address', false);
          }
        }
        break;
      case 'companyType':
        if (touched.companyType) {
          getCustomErrorMessageObj(
            customFieldError.companyType || '',
            'Company Type Required',
          )?.isValid === false &&
            setRequiredErrorMessage('companyType', 'Company Type', true);
          if (value === '') {
            setRequiredErrorMessage('companyType', 'Company Type', false);
          }
        }
    }
  };

  // * on form field blur
  const handleOnBlur = async (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formik: FormikProps<InitialValues>,
  ) => {
    const { name, value } = event.target;
    const { errors, values, setFieldValue } = formik;
    formik.handleBlur(event);
    setError(name as any, '');
    // * Add Validation Error
    if (value) {
      switch (name as FieldName) {
        case 'companyName':
          setUniqueErrorMessage('companyName', 'Company Name', value);
          if (+value.length < 6) {
            setError(
              'companyName',
              JSON.stringify([
                {
                  isValid: false,
                  message: 'Enter at least 6 characters',
                },
              ]),
            );
          } else if (+value.length >= 50)
            setError(
              'companyName',
              JSON.stringify([
                {
                  isValid: false,
                  message: 'Company Name should less than 50 latter',
                },
              ]),
            );
          break;
        case 'email':
          const isValidEmail = checkIsValidEmail(value);
          setUniqueErrorMessage('email', 'Email Address', value);
          !isValidEmail &&
            setError(
              'email',
              JSON.stringify([
                {
                  isValid: isValidEmail,
                  message: 'Email Address is Invalid',
                },
              ]),
            );
          break;
        default:
          break;
      }
    } else {
      // * set Is required error
      switch (name as FieldName) {
        case 'companyName':
          setRequiredErrorMessage('companyName', 'Company Name', false);
          break;
        case 'firstName':
          setRequiredErrorMessage('firstName', 'First Name', false);
          break;
        case 'lastName':
          setRequiredErrorMessage('lastName', 'Last Name', false);
          break;
        case 'email':
          setRequiredErrorMessage('email', 'Email Address', false);
          break;
        case 'companyType':
          setRequiredErrorMessage('companyType', 'Company Type', false);
          break;
        default:
          break;
      }
    }
  };

  const removeCheckError = (errorValue: string, field: FieldName) => {
    const errors = JSON.parse(errorValue || '[]') || [];
    const newErrors = errors.filter((error: CustomError) => !error.isValid);
    setError(field, JSON.stringify(newErrors));
  };

  // * Check given data is available or not
  const setUniqueErrorMessage = async (
    name: FieldName,
    label: string,
    value: string,
  ) => {
    const checkIsCompanyUnique = await checkIsUniqueName({
      [name as any]: value,
    });
    if (!checkIsCompanyUnique?.data?.success) {
      const error: CustomError[] = [
        {
          isValid: false,
          message: `${label} Unavailable`,
        },
      ];
      setError(name, JSON.stringify(error));
    }

    getCustomErrorMessageObj(
      customFieldError[name] || '',
      `${label} Unavailable`,
    )?.isValid === false &&
      setError(
        name,
        JSON.stringify([
          {
            isValid: !!checkIsCompanyUnique?.data?.success,
            message: !!checkIsCompanyUnique?.data?.success
              ? `${label} Available`
              : `${label} Unavailable`,
          },
        ]),
      );
  };

  // * Check given data is available or not
  const setRequiredErrorMessage = (
    name: FieldName,
    label: string,
    value: boolean,
    customMessage?: string,
  ) => {
    const error: CustomError[] = [
      {
        isValid: value,
        message: customMessage ?? `${label} Required`,
      },
    ];
    setError(name, JSON.stringify(error));
  };

  // * High light green message only id there is red message appear once
  const getCustomErrorMessageObj = (
    errorValue: string,
    messageToFind: string,
  ) => {
    const errors = JSON.parse(errorValue || '[]') || [];
    const ans = errors.find(
      (obj: CustomError) =>
        obj.message.toLowerCase() === messageToFind.toLowerCase(),
    );
    return ans;
  };

  const handleDebounceOnChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formik: FormikProps<InitialValues>,
  ) => {
    const { name, value } = event.target;
    const { touched } = formik;
    if (value) {
      switch (name as FieldName) {
        case 'companyName':
          if (touched.companyName) {
            setUniqueErrorMessage('companyName', 'Company Name', value);
          }
          break;
        case 'email':
          if (touched.email) {
            setUniqueErrorMessage('email', 'Email Address', value);
          }
          break;
        default:
          break;
      }
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (value: FormikValues, onNext: Function) => {
    setIsLoading(true);
    await Rest.post(apiUrls.REGISTER, value)
      .then(() => {
        setIsLoading(false);
        onNext(value);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return {
    initialValues,
    validationSchema,
    handleOnChange,
    customFieldError,
    handleOnBlur,
    removeCheckError,
    handleDebounceOnChange,
    handleSubmit,
    isLoading,
  };
};

export default useRegister;
