import styled from '@emotion/styled';
import { Switch, SwitchProps, alpha } from '@mui/material';
import { dark } from '@mui/material/styles/createPalette';
import { useEffect, useState } from 'react';

interface SwitchSizeConstants {
  switchHeight: number;
  switchWidth: number;
  thumbTransformX: string;
}
interface SwitchUIConstants {
  inActiveBgColor: string;
  inActiveTextColor: string;
  inActiveThumb: string;
  inActiveThumbHover: string;
  activeBgColor: string;
  borderColor: string;
  activeTextColor: string;
}

type SwitchUI = SwitchSizeConstants & SwitchUIConstants;

const BaseSwitch = styled(Switch, {
  shouldForwardProp: prop => prop !== 'switchUiConstants',
})<{
  switchUiConstants: SwitchUI;
}>(({ theme, switchUiConstants }) => ({
  padding: 0,
  '&.MuiSwitch-sizeSmall': {
    width: switchUiConstants.switchWidth,
    height: switchUiConstants.switchHeight,
  },
  '& .MuiSwitch-switchBase': {
    transitionDuration: '300ms',
    // * Disable
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: '0.25 !important',
    },

    //* When switch on
    '&.Mui-checked': {
      'webkit-transform': `translateX(${switchUiConstants.thumbTransformX})`,
      '-moz-transform': `translateX(${switchUiConstants.thumbTransformX})`,
      '-ms-transform': `translateX(${switchUiConstants.thumbTransformX})`,
      transform: `translateX(${switchUiConstants.thumbTransformX})`,
      //* when switch on -> track css
      '& + .MuiSwitch-track': {
        backgroundColor: switchUiConstants.activeBgColor,
        opacity: '1',
        '&::before': {
          color: switchUiConstants.inActiveTextColor,
          content: '"ON"',
          left: '48%',
        },
      },
      '.MuiSwitch-input': {
        width: switchUiConstants.switchWidth,
        left: `calc(${switchUiConstants.switchHeight}px - ${switchUiConstants.switchWidth}px)`,
      },
      '&:hover .MuiSwitch-thumb': {
        backgroundColor: switchUiConstants.inActiveThumb,
        opacity: 0.75,
      },
    },

    //* When switch is off
    '&:not(.Mui-checked)': {
      //* when switch off -> track css
      '& + .MuiSwitch-track': {
        backgroundColor: switchUiConstants.inActiveBgColor,
        '&::after': {
          color: switchUiConstants.activeTextColor,
          content: '"OFF"',
          left: '54%',
        },
      },
      '.MuiSwitch-input': {
        width: `${switchUiConstants.switchWidth}px`,
        right: `calc(${switchUiConstants.switchHeight}px - ${switchUiConstants.switchWidth}px)`,
        left: 0,
      },
      //* Mark hover
      '&:hover .MuiSwitch-thumb': {
        backgroundColor: switchUiConstants.inActiveThumbHover,
      },
    },

    //* Default
    '& + .MuiSwitch-track': {
      borderRadius: 22 / 2,
      border: '0.5px solid',
      borderColor: switchUiConstants.borderColor,
      opacity: '1', // Manage

      '&::before, &::after': {
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: 10,
        lineHeight: '14px',
      },
    },
  },

  '&.MuiSwitch-sizeSmall .MuiButtonBase-root': {
    padding: 0,
    margin: 0,
    width: switchUiConstants.switchHeight,
    height: switchUiConstants.switchHeight,
  },

  //*  Round thumb
  '&.MuiSwitch-sizeSmall .MuiSwitch-thumb': {
    margin: 0,
    boxShadow: 'none',
    boxSizing: 'border-box',
    width: switchUiConstants.switchHeight,
    height: switchUiConstants.switchHeight,
    border: '0.5px solid',
    borderColor: switchUiConstants.borderColor,
    backgroundColor: '#ffffff',
    transition: 'all 0.3s ease',
  },

  '.Mui-disabled .MuiSwitch-thumb': {
    borderColor: alpha(switchUiConstants.borderColor, 0.25),
  },
}));

export interface CosSwitchProps extends SwitchProps {
  version?: 'light' | 'dark';
  switchUIConstants?: SwitchUI;
}

const CosSwitch: React.FC<CosSwitchProps> = ({
  size = 'small',
  version = 'light',
  ...rest
}) => {
  const switchSize: SwitchSizeConstants = {
    switchHeight: 12,
    switchWidth: 40,
    thumbTransformX: '28px',
  };

  const lightSwitchUiConstants: SwitchUI = {
    ...switchSize,
    // inActiveBgColor: '#FFFFFF',
    inActiveBgColor: 'transparent',
    activeBgColor: '#001F38',

    inActiveTextColor: '#FFFFFF',
    activeTextColor: '#001F38',

    inActiveThumb: '#FFFFFF',
    inActiveThumbHover: '#001F38',
    borderColor: '#001F38',
  };

  const darkSwitchUiConstants: SwitchUI = {
    ...switchSize,
    // inActiveBgColor: '#001F38',
    inActiveBgColor: 'transparent',
    activeBgColor: '#07B9EC',

    inActiveTextColor: '#ffffff',
    activeTextColor: '#07B9EC',

    inActiveThumb: '#FFFFFF',
    inActiveThumbHover: '#07B9EC',
    borderColor: '#07B9EC',
  };

  return (
    <>
      <BaseSwitch
        switchUiConstants={
          version === 'dark' ? darkSwitchUiConstants : lightSwitchUiConstants
        }
        disableFocusRipple
        disableTouchRipple
        disableRipple
        size={size}
        {...rest}
      />
    </>
  );
};
export default CosSwitch;
