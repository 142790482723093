import React from 'react';
import { CosTypoGraphy } from '../../../../components';
import Counter, { CountProps } from './counter/Counter';
import images from '../../../../assets/images';

interface Props {
  afterConfiguring: Function;
  countProps?: Omit<CountProps, 'startFrom' | 'upto'>;
  domainName?: string;
}

const ConfiguringDomainModal: React.FC<Props> = ({
  afterConfiguring,
  countProps,
  domainName,
}) => {
  return (
    <div className="custommodel flex flex-col items-center bg-white-whale-500 p-0">
      <CosTypoGraphy
        variant="h5"
        fontFamily="controller"
        className="text-center text-24/29 -tracking-0.43"
      >
        Configuring your Customized Domain
      </CosTypoGraphy>
      {/* mb-1 */}
      <p className="mb-1.5 text-18/24 font-semibold leading-5 text-buddha-love-800">
        {/* // TODO :  manage dynamic counter */}
        Optimizing{' '}
        <Counter
          upto="100"
          startFrom="40"
          afterCompleted={afterConfiguring}
          {...countProps}
        />
        %
      </p>
      <div className="mb-5 ml-1">
        <div className="h-[94px] w-[94px] rounded-full bg-[#091F38]">
          <img src={images.yellowGif} />
        </div>
      </div>
      <div
        className="mx-auto w-fit rounded bg-white px-5 py-2 font-controller text-12/18 text-dark-blue-900"
        style={{
          boxShadow: '0px 0px 3px #A8B1B8',
        }}
      >
        {' '}
        https://{domainName}.sch-ops.com
      </div>
      <p className="mb-2.5 mt-4 font-semibold tracking-normal text-dark-blue-900">
        We are preparing your{' '}
        <span className="font-controller text-buddha-love-800">
          Enhanced Optimization
        </span>{' '}
        environment.
      </p>
      <p className="m-0 text-center text-12/18 text-blue-gray-100">
        Allow us a few moments to complete your configuration. This may take
        longer <br />
        depending on your internet connection and database speeds.
      </p>
    </div>
  );
};

export default ConfiguringDomainModal;
