import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import { getLocalStorageItem } from '../../libs/utils';

// TODO : fix auth router
export default function AuthRouter({ children }: any) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);
  const userData = getLocalStorageItem('cosUserData');
  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      setIsAuthenticated(true);
      navigate('/company_registration');
    } else {
      setIsAuthenticated(false);
    }
  }, [location]);

  return isAuthenticated
    ? navigate('/company_registration', { replace: true })
    : children;
}
