import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Alert, AlertProps } from '@mui/material';
import { Check, Close } from '../../assets/svgs';
import clsx from 'clsx';

const BaseAlert = styled(Alert)(({ theme }) => ({
  '&.MuiPaper-root': {
    fontSize: 12,
    lineHeight: '18px',
    height: 21,
    alignItems: 'center',
    borderRadius: 3,
    border: '1px solid currentColor',
    borderLeft: '3px solid currentColor',
    padding: '3.5px 7px',
    paddingLeft: '0',
    boxShadow: 'none',
  },

  '&.MuiAlert-standardSuccess': {
    backgroundColor: '#D4EDDA',
    color: '#28A745',
  },
  '&.MuiAlert-standardError': {
    color: '#EB5050',
    backgroundColor: '#FDEDED',
    // '& .MuiAlert-icon': {
    // marginTop: 2.45,
    // },
  },
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
  },
  '& .MuiAlert-icon': {
    padding: '0 7px',
    marginRight: 0,
  },
  '& .MuiAlert-message': {
    padding: '0',
    overflow: 'revert',
  },
}));

export interface CosAlertProps extends AlertProps {
  children: React.ReactNode;
  icon?: React.ReactNode | false | true;
}

const CosAlert: React.FC<CosAlertProps> = props => {
  const { children, icon = true, className, ...rest } = props;
  const alertIcon = icon === true ? undefined : icon;

  return (
    <BaseAlert
      className={clsx('mt-[5px]', className)}
      {...rest}
      iconMapping={{
        success: <Check fill="#28A745" width={12} height={9} />,
        error: <Close fill="#eb5050" width={12} height={9} />,
      }}
      icon={alertIcon}
    >
      {children}
    </BaseAlert>
  );
};

export default CosAlert;
