import React, { SyntheticEvent, useState } from 'react';
import Autocomplete, {
  AutocompleteProps,
  autocompleteClasses,
} from '@mui/material/Autocomplete';
import {
  Box,
  ChipTypeMap,
  Paper,
  Popper,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
  alpha,
  styled,
} from '@mui/material';
import CosLabel, { CosLabelProps } from './CosLabel';
import clsx from 'clsx';
import { moveTabToNextField } from '../../../libs/helper';

export interface CosAutoCompleteProps<
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
> extends Omit<
    AutocompleteProps<
      Value,
      Multiple,
      DisableClearable,
      FreeSolo,
      ChipComponent
    >,
    'renderInput'
  > {
  label?: string;
  isRequired?: boolean;
  labelProps?: Omit<CosLabelProps, 'children'>;
  inputSx?: SxProps<Theme>;
  tooltipTitle?: string;
  inputProps?: TextFieldProps;
  name?: string;
  placeholder?: string;
}

const CosAutoSelectInput = styled(TextField, {
  shouldForwardProp: prop => prop !== 'inputSx',
})<{
  inputSx?: SxProps<Theme>;
}>(({ inputSx }) => {
  return {
    width: '100%',
    '&:hover': {
      '.MuiSvgIcon-root': {
        fill: '#07B9EC',
      },
      '.MuiInputBase-input': {
        color: alpha('#001f38', 0.75),
      },
    },
    '& .MuiAutocomplete-input': {
      padding: '0 !important',
      backgroundColor: 'transparent',
      color: '#001F38',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '18px',
      cursor: 'pointer',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '3px',
      border: 'unset !important',
      outline: 'unset !important',
    },
    '& .MuiInputBase-input:focus': {
      '&::placeholder': {
        opacity: 0.75,
      },
    },
    '& .MuiInputBase-input:hover': {
      '&::placeholder': {
        opacity: 0.75,
      },
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#001f38',
      fontWeight: '400',
      opacity: 1,
      transition: 'opacity 0.3s ease',
    },
    '& .MuiButtonBase-root': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiSvgIcon-root': {
      fill: '#001F38',
    },
    '& .MuiInputBase-root': {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 10,
      paddingRight: '30px !important',
      backgroundColor: '#ffffff',
      height: '34px',
      cursor: 'pointer',
      ...(inputSx && inputSx),
    },
    '& .MuiAutocomplete-endAdornment': {
      right: '2px !important',
    },
  };
});

const CosAutoComplete = <
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>({
  inputSx,
  isRequired,
  label,
  labelProps,
  tooltipTitle,
  className,
  inputProps,
  name = '',
  placeholder,
  onChange,
  ...rest
}: CosAutoCompleteProps<
  Value,
  Multiple,
  DisableClearable,
  FreeSolo,
  ChipComponent
>) => {
  return (
    <div className={clsx('mb-4 inline-block w-full', className)}>
      {label && (
        <div className="flex">
          <CosLabel
            isRequired={isRequired}
            {...labelProps}
            tooltipTitle={tooltipTitle}
          >
            {label}
          </CosLabel>
        </div>
      )}
      <Autocomplete
        openOnFocus
        openText={''}
        closeText={''}
        clearIcon={false}
        loadingText={'Loading…'}
        onChange={(event: any, option: any, reason, details) => {
          const element: any = event.target;
          element?.setAttribute('name', name);
          element?.setAttribute('value', option?.value);
          onChange && onChange(event, option, reason, details);
          //* FOR move focus to next field
          setTimeout(() => {
            if (event?.key === 'Enter') {
              moveTabToNextField();
            }
          }, 0);
        }}
        filterOptions={(options, state) => {
          const { inputValue } = state;
          if (inputValue) {
            return options.filter((option: any) =>
              option.label
                .toLowerCase()
                .startsWith(inputValue.trim().toLowerCase()),
            );
          } else {
            return options;
          }
        }}
        ListboxProps={{
          onMouseEnter: (event: any) => {
            event.target.classList.remove('Mui-focused');
            event.target.classList.add('Mui-focused');
          },

          sx: {
            '&': {
              margin: '6px 0',
              padding: 0,
            },
            '& .MuiAutocomplete-option': {
              padding: '0 10px',
              fontSize: '12px',
              lineHeight: '10px',
              textTransform: 'capitalize',
              color: '#001F38',
              height: 19.5,
              width: '100%',
              '&.Mui-focusVisible': {
                backgroundColor: 'transparent',
              },
            },
            '& .MuiAutocomplete-option.Mui-focused': {
              backgroundColor: 'transparent',
              color: '#07B9EC',
            },
            '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
              backgroundColor: '#EDEEEF',
              color: '#07B9EC',
            },
            '& .MuiAutocomplete-option[aria-selected="true"]': {
              backgroundColor: '#EDEEEF',
              color: '#001F38',
            },
          },
        }}
        PaperComponent={({ children, className, ...paperProps }) => (
          <Paper className={clsx(className, 'rounded-t-none')} {...paperProps}>
            {children}
          </Paper>
        )}
        PopperComponent={({ children, className, sx, ...popperProps }) => (
          <Popper
            {...popperProps}
            className={clsx(className, 'rounded-t-none')}
            onMouseLeave={() => {
              const listItems = document.querySelectorAll(
                '.MuiAutocomplete-option',
              );
              listItems.forEach(item => {
                if (item.getAttribute('aria-selected') === 'true') {
                  item.classList.add('color-animation');
                  item.classList.add('Mui-focused');
                } else {
                  item.classList.remove('color-animation');
                  item.classList.remove('Mui-focused');
                }
              });
            }}
            onMouseEnter={() => {
              const listItems = document.querySelectorAll(
                '.MuiAutocomplete-option',
              );
              listItems.forEach(item => {
                item.classList.remove('color-animation');
                item.classList.remove('Mui-focused');
              });
            }}
            sx={{
              ...sx,
              boxShadow: '0px 0px 3px #A8B1B8',
              borderRadius: '7px',
              '& .MuiAutocomplete-paper': {
                boxShadow: 'none',
                borderRadius: '7px',
              },
              '& .MuiAutocomplete-noOptions, & .MuiAutocomplete-loading': {
                padding: '6px 10px',
                fontSize: '12px',
                lineHeight: '10px',
                textTransform: 'capitalize',
                color: '#001F38',
                height: 19.5,
              },
            }}
            placement="bottom"
          >
            {children}
          </Popper>
        )}
        {...rest}
        renderInput={params => (
          <CosAutoSelectInput
            type="text"
            name={name}
            placeholder={placeholder}
            {...params}
            inputSx={inputSx}
            {...inputProps}
          />
        )}
      />
    </div>
  );
};

export default CosAutoComplete;
