import { Field, FieldProps } from 'formik';
import CosRadioBox, { CosRadioButtonProps } from './ui/CosRadiobox';
import React from 'react';
import { ErrorMessage } from './ui';

interface Props extends CosRadioButtonProps {
  name: string;
}

const FormRadiobox: React.FC<Props> = ({ name, ...rest }) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <React.Fragment>
          <div>
            <CosRadioBox
              checked={meta.value as boolean}
              error={!!(meta.touched && meta.error)}
              {...field}
              name={name}
              {...rest}
            />
            {meta.touched && meta.error && (
              <ErrorMessage message={meta.error as string} />
            )}
          </div>
        </React.Fragment>
      )}
    </Field>
  );
};
export default FormRadiobox;
