import AuthLayout from '../../layout/auth/AuthLayout';
import images from '../../assets/images';
import { SignUpForm } from '../../modules/auth/register/components';
import './signUpPage.css';
import { CosTypoGraphy } from '../../components';
import { useState } from 'react';
import { FormikValues } from 'formik';
import { constant } from '../../libs/constant';
import { SchedulrOpsTertiarylogo } from '../../assets/svgs';

const SignUpPage = () => {
  const [showConfiguringDomainModal, setShowConfiguringDomainModal] =
    useState(false);

  const handleFormSubmit = (value: FormikValues) => {
    setShowConfiguringDomainModal(true);
  };

  return (
    <AuthLayout>
      <section className="fixed-header-position sm:mx-[50px] lg:flex lg:h-[calc(100vh-175px)] xl:mx-[175px]">
        <div className="sm-order-1 order-1 md:order-1 lg:order-2 lg:w-1/2 xl:order-2">
          <div className="relative">
            <div
              className="hero-caption relative mb-2.5 flex items-center px-[22px] py-[18px] sm:items-start sm:pb-[18px] lg:h-[216px] xl:pb-[0px]"
              style={{
                filter:
                  'drop-shadow(0px 3px 6px #00000029) drop-shadow(0px 3px 6px #00000029)',
              }}
            >
              <div className="flex flex-wrap items-center lg:mb-4">
                <SchedulrOpsTertiarylogo className="sizing mr-1" />
                <CosTypoGraphy
                  className="!inline text-center text-lg -tracking-heading"
                  fontFamily="controller"
                >
                  Enhanced Optimization
                </CosTypoGraphy>
              </div>
            </div>
            <div
              className="hero-info relative z-10 px-[22px] py-[18px]"
              style={{
                filter:
                  'drop-shadow(0px 3px 6px #00000029) drop-shadow(0px 3px 6px #00000029)',
              }}
            >
              <CosTypoGraphy
                className="mb-2 !inline-block text-center text-lg -tracking-heading"
                fontFamily="controller"
              >
                Included in Your {constant.freeTrailDays} Day Trial
              </CosTypoGraphy>

              <section>
                <div>
                  <ul className="m-0 list-image-roundChecked pb-4 pl-4 text-xs -tracking-text">
                    <li>
                      <span className="font-semibold">Unlimited </span>use of
                      small or medium package
                    </li>
                    <li>
                      <span className="font-semibold">Unlimited </span>
                      schedules & schedule templates
                    </li>
                    <li>
                      <span className="font-semibold">Unlimited </span>
                      projects
                    </li>
                    <li>
                      <span className="font-semibold">No </span>upfront cost
                    </li>
                    <li>
                      Your database will be kept for
                      <span className="font-semibold"> 30 days </span>after
                      trial expires
                    </li>
                    <li>
                      Annual discounts up to
                      <span className="font-semibold"> 20% </span>
                      available if purchased before trial expires
                    </li>
                  </ul>
                  <div className="text-[13px] font-bold tracking-text">
                    "No Commitment" "No Obligation" "No Credit Card Required"
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div className="order-2 h-auto bg-black-Pearl-900 sm:order-2 md:order-2 lg:order-1 lg:w-1/2 xl:order-1">
          <SignUpForm onFormSubmit={handleFormSubmit} />
        </div>
      </section>
    </AuthLayout>
  );
};

export default SignUpPage;
