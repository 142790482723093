import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import clsx from 'clsx';

const tabHeight = 9;
const tabWidth = 80;
const tabBorderRadius = 80;

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

interface StyledTabProps {
  label: string;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  cursor: 'default',
  height: tabHeight,
  minHeight: 'unset',
  '& .MuiTabs-flexContainer': {
    justifyContent: 'center',
    height: tabHeight,
    gap: '5px',
  },
  '& .MuiTab-root': {
    minHeight: 'unset',
    minWidth: 'unset',
    height: tabHeight,
    width: tabWidth,
    padding: 0,
    backgroundColor: '#ffffff',
    borderRadius: tabBorderRadius,
    cursor: 'default',
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    width: 'auto !important',
    height: tabHeight,
  },
  '& .MuiTabs-indicatorSpan': {
    borderRadius: tabBorderRadius,
    width: tabWidth,
    backgroundColor: '#001F38',
  },
});

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} tabIndex={-1} />
))(({ theme }) => ({
  // pointerEvents: 'none',
}));

interface Props {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  defaultValue?: number;
  onChange?: (value: number) => void;
  disable?: boolean;
}

const SliderTab: React.FC<Props> = ({
  className,
  onChange,
  disable,
  defaultValue = 0,
}: any) => {
  const [value, setValue] = React.useState(defaultValue);

  useEffect(() => {
    setValue(() => defaultValue);
  }, [defaultValue]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onChange && onChange(newValue);
    !disable && setValue(newValue);
  };

  return (
    <Box component="div" className={clsx(className)} tabIndex={-1}>
      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="styled tabs example"
      >
        <StyledTab label="" />
        <StyledTab label="" />
      </StyledTabs>
    </Box>
  );
};

export default SliderTab;
