import { FC, ReactNode, useState } from 'react';
import { InputLabel, InputLabelProps, styled } from '@mui/material';
import CosTooltip from '../../CosTooltip';
import { Question, QuestionDark } from '../../../assets/svgs';

export interface CosLabelProps extends InputLabelProps {
  children: ReactNode;
  isRequired?: boolean;
  toolTip?: string;
  tooltipTitle?: string;
  icon?: any;
  hoverIcon?: any;
}

const ExtendedInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '19px',
  color: '#001F38',
  textAlign: 'left',
  marginBottom: '4px',
  '& .MuiInputLabel-asterisk': {
    color: '#eb5050',
  },
}));

const CosLabel: FC<CosLabelProps> = ({
  children,
  isRequired = true,
  tooltipTitle,
  icon,
  hoverIcon,
  ...rest
}) => {
  const [isShowOpen, setShowOpen] = useState(false);
  return (
    <>
      <ExtendedInputLabel required={isRequired} {...rest}>
        {children}
      </ExtendedInputLabel>
      {tooltipTitle && (
        <>
          &nbsp;
          <CosTooltip
            onClose={() => setShowOpen(false)}
            onOpen={() => setShowOpen(true)}
            title={tooltipTitle}
            placement="right"
            dynamicdecoration="underline"
            theme="light"
          >
            {isShowOpen ? (
              icon ? (
                icon
              ) : (
                <Question style={{ marginLeft: '4px' }} />
              )
            ) : hoverIcon ? (
              hoverIcon
            ) : (
              <QuestionDark style={{ marginLeft: '4px' }} />
            )}
          </CosTooltip>
        </>
      )}
    </>
  );
};

export default CosLabel;
