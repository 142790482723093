import React from 'react';
import Masterlayout from '../layout/masterlayout/Masterlayout';
import { Box } from '@mui/material';
import { Link, Outlet } from 'react-router-dom';

interface Props {
  children?: React.ReactNode;
}

const StandardDashboard: React.FC<Props> = ({ children }) => {
  return (
    <Masterlayout>
      <Box
        sx={{
          height: 'calc(100vh - 116px)',
          margin: 0,
          background: 'white',
          borderRadius: '4px',
          boxShadow: '0px 0px 4px #00000017',
        }}
      >
        <Box className="flex h-full p-2">
          <Box className="w-[200px] bg-white-whale-500 p-2">
            <Link to={'/standard/switch'} className="cos-link">
              Switch
            </Link>
          </Box>
          <Box className="flex-grow pl-2">
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Masterlayout>
  );
};

export default StandardDashboard;
