import React, { useEffect, useLayoutEffect, useState } from 'react';
import LoginForm from '../modules/auth/login/components/LoginForm';
import AuthLayout from '../layout/auth/AuthLayout';
import { FormikValues } from 'formik';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {
  cleanAllLocalStorage,
  isDomainNameAvailable,
  removeLocalStorageItem,
} from '../libs/utils';

const LoginPage = () => {
  const navigate = useNavigate();
  const [showConfiguringDomainModal, setShowConfiguringDomainModal] =
    useState(false);

  const handleFormSubmit = (value: FormikValues) => {
    setShowConfiguringDomainModal(true);
  };

  // // * Remove all local storage data before render
  // useLayoutEffect(() => {
  //   removeLocalStorageItem('cosUserData');
  //   cleanAllLocalStorage();
  // }, []);

  useLayoutEffect(() => {
    const isValidURL = isDomainNameAvailable();
    if (isMobile) {
      navigate('/pageinfo', { replace: true });
    } else if (!isValidURL) {
      navigate('/register', { replace: true });
    }
  }, []);

  return (
    <AuthLayout>
      <LoginForm onFormSubmit={handleFormSubmit} />
    </AuthLayout>
  );
};

export default LoginPage;
