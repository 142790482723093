import React from 'react';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import { Box, CssBaseline } from '@mui/material';
import DrawerHeader from './components/ui/AppBarLeftSideBarHeader';

const Masterlayout = ({ children }: any) => {
  const [open, setOpen] = React.useState(true);

  const toggleSideBar = () => {
    setOpen(preValue => !preValue);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Navbar open={open} />
      <Sidebar open={open} handleDrawerClose={toggleSideBar} />
      <Box component="main" className="flex-grow bg-white-whale-500 p-2">
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
};

export default Masterlayout;
