const useCompanyRegistration = () => {
  const initialValues = {
    companyName: '',
  };
  // const validationSchema = {}
  return {
    initialValues,
  };
};
export default useCompanyRegistration;
