import React, { useEffect, useLayoutEffect, useState } from 'react';
import { CosButton } from '../components';
import { ImportComplete } from '../assets/svgs';
import AuthLayout from '../layout/auth/AuthLayout';
import { useLocation } from 'react-router-dom';
import Rest from '../Rest';
import { apiUrls } from '../apiUrl';
import { constant } from '../libs/constant';
import { useNavigate } from 'react-router-dom';

const EmailVerificationPage = () => {
  const [isGetHelp, setIsGetHelp] = useState(false);
  const location = useLocation();
  const email = location.state && location.state?.email;
  const navigate = useNavigate();

  useLayoutEffect(() => {
    !email && navigate('/register', { replace: true });
  }, []);

  const handleResendMail = () => {
    Rest.post(apiUrls.EMAIL_RESEND, { email: email }).then(response => {
      setIsGetHelp(true);
    });
  };

  return (
    <AuthLayout>
      <section className="positionginmobile flex h-[calc(100vh-137px)] items-center justify-center sm:h-[calc(100vh-85px)] lg:h-[calc(100vh-175px)] lg:h-full">
        <div
          className="sm-mx-0 mx-8 rounded-md bg-white bg-opacity-75 px-3 py-[15px] text-center md:mb-[35px] md:w-[673px] lg:mb-[57px]"
          style={{
            boxShadow: '0px 0px 3px #A8B1B8',
          }}
        >
          <p className="mt-0 text-16/18 font-semibold tracking-normal text-dark-blue-900">
            Your customized{' '}
            <span className="font-controller text-buddha-love-800">
              Enhanced Optimization
            </span>{' '}
            environment is ready.
          </p>

          <p className="m-[15px] font-controller text-24/34 font-semibold -tracking-0.5">
            {isGetHelp
              ? 'Email Resent, Confirm Your Email'
              : 'Confirm Your Email'}
          </p>
          <div className="mb-4  ml-1 mt-[5px]">
            <ImportComplete />
          </div>

          <p className="text-12/18 text-blue-gray-100">
            Please confirm your email address to begin your free, no commitment,
            <br></br>
            no obligation {constant.freeTrailDays} day optimization trial.
          </p>

          {isGetHelp ? (
            <p className="mb-0 text-12/18 text-blue-gray-100">
              Please check your spam folder, if you have not received your email{' '}
              <br></br>
              within 15 minutes contact our support team below.
            </p>
          ) : (
            <p className="mb-0 text-12/18 text-blue-gray-100">
              Please check your spam folder, if you have not received your email{' '}
              <br></br>
              within 15 minutes you may try the resend email option below.
            </p>
          )}

          {isGetHelp ? (
            <CosButton
              className="mt-5 h-5 w-[108px] px-3 py-0 text-12/17"
              disabled={true}
            >
              Get Help
            </CosButton>
          ) : (
            <CosButton
              className="mt-5 h-5 w-[108px] px-3 py-0 text-12/17"
              onClick={handleResendMail}
            >
              Resend Email
            </CosButton>
          )}
        </div>
      </section>
    </AuthLayout>
  );
};

export default EmailVerificationPage;
