import axios, { AxiosError, AxiosResponse } from 'axios';
// import {getToken} from './storage';
// import {errorAlert} from '../components/alert';
// import {shouldNotShowErrorAlert} from './helpers';

let Rest = axios.create({
  baseURL: process.env.REACT_APP_API_KEY,
});

// Rest.interceptors.request.use(
//   async config => {
//     const authToken = await getToken();
//     if (authToken) {
//       config.headers.Authorization =
//         config.headers.Authorization || `Token ${authToken}`;
//     }
//     return config;
//   },
//   (error: AxiosError) => Promise.reject(error),
// );

Rest.interceptors.response.use(
  (response: AxiosResponse) => {
    // console.info(`response : ${response.config.url}`, response);
    return response;
  },
  (error: AxiosError) => {
    console.error(`error : ${error.config?.url}`, error);

    // if (shouldNotShowErrorAlert(error?.config?.url as string)) {
    //   return Promise.reject(error);
    // }

    // switch (error?.response?.status) {
    //   case 401 || 403:
    //     errorAlert({message: 'Unauthorized'});
    //     break;

    //   case 402:
    //     errorAlert({message: 'Payment is required'});
    //     break;

    //   case 404:
    //     errorAlert({message: 'Not Found'});
    //     break;

    //   case 500:
    //     errorAlert({message: 'Server Error'});
    //     break;

    //   default:
    //     const errorMessages: any = Object.values(error?.response?.data)[0];
    //     const message: string = Array.isArray(errorMessages)
    //       ? (errorMessages[0] as string)
    //       : errorMessages;
    //     errorAlert({message});
    // }
    return Promise.reject(error);
  },
);

export default Rest;
