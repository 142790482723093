import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import clsx from 'clsx';

const SvgWrapper: React.FC<SvgIconProps> = ({ viewBox, children, ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox={viewBox || '0 0 24 24'}>
      {children}
    </SvgIcon>
  );
};

export default SvgWrapper;
