import { Formik, FormikValues } from 'formik';
import useCompanyRegistration from '../hooks/useCompanyRegistrationFrom';

import Card from '@mui/material/Card';
import { CosInput, CosLabel } from '../../../components/formikForms/ui';
import { FormCheckbox } from '../../../components/formikForms';
import FormRadiobox from '../../../components/formikForms/FormRadiobox';
import { getLocalStorageItem } from '../../../libs/utils';
import { Box } from '@mui/material';

interface Props {
  // onFormSubmit: (formValue: FormikValues) => void;
}

const CompanyRegistrationFrom: React.FC<Props> = ({}) => {
  const { initialValues } = useCompanyRegistration();
  // TODO : set or create data type
  const userDetails: any = getLocalStorageItem('cosUserData');
  const name =
    userDetails?.firstName.toCapitalize() +
    ' ' +
    userDetails?.lastName.toCapitalize();

  const handleSubmit = () => {};
  return (
    <>
      <Box
        component="main"
        // className="m-2.5"
        className="bg-white"
        sx={{
          boxShadow: '0px 0px 4px #00000017',
          borderRadius: '4px',
        }}
      >
        <div className="h-full pl-[15px] pt-[15px]">
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <div className="relative flex gap-4">
              <span className="top[-12px] absolute right-[5px] text-11/15 tracking-normal text-dark-red-500">
                * Required Fields
              </span>
              <div className="">
                {' '}
                <CosInput
                  label="CompanyName (Active Company)"
                  name="companyName"
                  value={userDetails?.companyName}
                  placeholder="Enter Your Company Name"
                  readOnly
                  inputSx={{
                    width: '270px',
                    height: '28px',
                    marginRight: '25px',
                    boxShadow: '0px 0px 3px #A8B1B8',
                    border: 'unset',
                    textTransform: 'capitalize',
                  }}
                />
              </div>
              <div className="">
                {' '}
                <CosInput
                  label="Full Legal Name"
                  name="fulllegalname"
                  placeholder="Enter Full Legal Name "
                  inputSx={{
                    width: '270px',
                    height: '28px',
                    marginRight: '25px',
                    boxShadow: '0px 0px 3px #A8B1B8',
                    border: 'unset',
                  }}
                />
              </div>
              <div className="">
                {' '}
                <CosInput
                  label="DBA Name (If Applicable)"
                  name="dbaname"
                  isRequired={false}
                  placeholder="Enter Full Legal Name "
                  inputSx={{
                    width: '270px',
                    height: '28px',
                    marginRight: '25px',
                    boxShadow: '0px 0px 3px #A8B1B8',
                    border: 'unset',
                  }}
                />
              </div>
              <div className="">
                <CosLabel>Organization Type</CosLabel>
                <div className="flex">
                  <FormCheckbox
                    name="OrganizationType"
                    isRequired={false}
                    label={<>For-Profit</>}
                    labelProps={{
                      className:
                        '!mb-0 text-dark-blue-900 flex mr-5 items-center !mb-0 text-[13px]',
                    }}
                  />
                  <FormCheckbox
                    name="OrganizationType"
                    isRequired={false}
                    label={<>Non-Profit</>}
                    labelProps={{
                      className:
                        '!mb-0 text-dark-blue-900 flex mr-5 items-center !mb-0 text-[13px]',
                    }}
                  />
                </div>
              </div>
              <div className="">
                <CosLabel>Tax Exempt </CosLabel>
                <div className="flex">
                  <FormRadiobox
                    name="OrganizationType"
                    isRequired={false}
                    label={<>Yes</>}
                    labelProps={{
                      className:
                        '!mb-0 text-dark-blue-900 flex mr-5 items-center !mb-0 text-[13px]',
                    }}
                  />
                  <FormRadiobox
                    name="OrganizationType"
                    isRequired={false}
                    label={<>No</>}
                    labelProps={{
                      className:
                        '!mb-0 text-dark-blue-900 flex mr-5 items-center !mb-0 text-[13px]',
                    }}
                  />
                </div>
              </div>
            </div>
          </Formik>
        </div>
      </Box>
    </>
  );
};
export default CompanyRegistrationFrom;
