import { Form, Formik, FormikValues } from 'formik';
import { FormAutoComplete, FormInput } from '../../../components/formikForms';
import { CosButton, CosFormErrorAlert } from '../../../components';
import useRegister from './hooks/useRegister';
import { CustomError } from '../../../libs/interface';
import clsx from 'clsx';
import { useGetCompanyTypeQuery } from '../../../services/common/companyType.service';
import { hasAnyCustomError } from '../../../libs/utils';

interface Props {
  onNext: (formValue: FormikValues) => void;
}

const labelProps = {
  className: 'font-semibold !leading-[18px]',
};

const inputSx = {
  border: 'unset',
  height: 45,
  boxShadow: '0px 0px 3px #A8B1B8',
};

const RegisterYourCompanyForm: React.FC<Props> = ({ onNext }) => {
  const {
    initialValues,
    validationSchema,
    handleOnChange,
    customFieldError,
    handleOnBlur,
    handleDebounceOnChange,
    handleSubmit,
    isLoading,
  } = useRegister();

  const {
    data,
    error,
    isLoading: isCompanyTypeLoading,
  } = useGetCompanyTypeQuery({});

  const renderPasswordErrors = (errors: CustomError[], className?: string) => {
    return (
      errors && (
        <div className="flex flex-col gap-1">
          {errors.map((err: CustomError) => {
            return (
              <CosFormErrorAlert
                severity={err.isValid ? 'success' : 'error'}
                className={clsx(className)}
                key={err.message}
                icon={true}
                sx={{
                  width: '100% !important',
                  maxHeight: 27,
                }}
              >
                {err.message}
              </CosFormErrorAlert>
            );
          })}
        </div>
      )
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async values => {
        await handleSubmit(values, onNext);
      }}
      validateOnBlur
    >
      {formik => {
        const { dirty, values, isValid, setFieldValue } = formik;
        return (
          <Form>
            <section className="flex max-h-[100%] min-h-[100%] flex-col gap-[15px] overflow-auto p-[2px] pr-[30px] sm:max-h-[370px] sm:min-h-[370px] sm:pr-[79px]">
              <div>
                <FormInput
                  autoFocus
                  hideDefaultError
                  label="COMPANY NAME"
                  labelProps={labelProps}
                  name="companyName"
                  placeholder="YOUR COMPANY NAME"
                  className="!mb-0"
                  inputSx={{
                    textTransform: 'capitalize',
                    ...inputSx,
                  }}
                  onChange={event => handleOnChange(event, formik)}
                  onBlur={event => handleOnBlur(event, formik)}
                  debounceCallback={event =>
                    handleDebounceOnChange(event, formik)
                  }
                />
                {customFieldError.companyName &&
                  renderPasswordErrors(
                    JSON.parse(customFieldError.companyName),
                  )}
              </div>
              <div className="flex flex-col gap-[15px] md:flex-row">
                <div className="flex-1">
                  <FormInput
                    hideDefaultError
                    label="FIRST NAME"
                    labelProps={labelProps}
                    name="firstName"
                    placeholder="FIRST NAME"
                    className="!mb-0"
                    inputSx={{
                      textTransform: 'capitalize',
                      ...inputSx,
                    }}
                    onChange={event => handleOnChange(event, formik)}
                    onBlur={event => handleOnBlur(event, formik)}
                  />
                  {customFieldError.firstName &&
                    renderPasswordErrors(
                      JSON.parse(customFieldError.firstName),
                    )}
                </div>
                <FormInput
                  label="INT."
                  labelProps={{
                    required: false,
                    ...labelProps,
                  }}
                  name="int"
                  placeholder="INT."
                  className="int-sx-device !mb-0 lg:!w-[71.6px]"
                  inputSx={{
                    textTransform: 'uppercase',
                    textAlign: 'center',
                    ...inputSx,
                  }}
                />
                <div className="flex-1">
                  <FormInput
                    hideDefaultError
                    label="LAST NAME"
                    labelProps={labelProps}
                    name="lastName"
                    className="!mb-0"
                    placeholder="LAST NAME"
                    inputSx={{
                      textTransform: 'capitalize',
                      ...inputSx,
                    }}
                    onChange={event => handleOnChange(event, formik)}
                    onBlur={event => handleOnBlur(event, formik)}
                  />
                  {customFieldError.lastName &&
                    renderPasswordErrors(JSON.parse(customFieldError.lastName))}
                </div>
              </div>
              <div>
                <FormInput
                  hideDefaultError
                  label="EMAIL ADDRESS"
                  labelProps={labelProps}
                  className="!mb-0"
                  name="email"
                  placeholder="EMAIL ADDRESS"
                  inputSx={{
                    ...inputSx,
                  }}
                  onChange={event => handleOnChange(event, formik)}
                  onBlur={event => handleOnBlur(event, formik)}
                  debounceCallback={event =>
                    handleDebounceOnChange(event, formik)
                  }
                />
                {customFieldError.email &&
                  renderPasswordErrors(JSON.parse(customFieldError.email))}
              </div>
              <div>
                <FormAutoComplete
                  hideDefaultError
                  loading={isCompanyTypeLoading}
                  name="companyType"
                  placeholder="MY COMPANY IS A ……."
                  label={'CHOOSE YOUR COMPANY TYPE'}
                  labelProps={labelProps}
                  className="!mb-0"
                  inputSx={inputSx}
                  options={data?.data?.result || []}
                  onChange={(event: any) =>
                    handleOnChange(event, formik, 'selectBox')
                  }
                  onBlur={(event: any) => {
                    handleOnBlur(event, formik);
                  }}
                />
                {customFieldError.companyType &&
                  renderPasswordErrors(
                    JSON.parse(customFieldError.companyType),
                    '!mb-2',
                  )}
              </div>
            </section>
            <div className="static left-0 right-0 m-auto text-center sm:fixed">
              <CosButton
                className="mb-[40px] mt-[30px] text-18/24 font-semibold"
                type="submit"
                sx={{
                  height: 35,
                  width: 140,
                  margin: 'auto',
                }}
                disabled={
                  !(dirty && isValid && !hasAnyCustomError(customFieldError))
                }
                isLoading={isLoading}
              >
                Next
              </CosButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RegisterYourCompanyForm;
