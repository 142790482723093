import * as Yup from 'yup';
import { CustomError } from '../../../../libs/interface';
import { ChangeEvent, useState } from 'react';
import { validateUserPassword } from '../../../../libs/helper';

import Rest from '../../../../Rest';
import { ErrorMessage, FormikProps } from 'formik';
import { apiUrls } from '../../../../apiUrl';
import lengthChecker from 'string-pixel-width';
import { checkIsValidEmail } from '../../../../libs/utils';

export interface SignUpFromInitialValues {
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  domainName: string;
  userName: string;
  password: string;
  country: string;
  terms: boolean;
  keepMeUpdate: boolean;
  int: string;
}
export type FieldName = keyof SignUpFromInitialValues;
type StateTypeForValue = Partial<Record<FieldName, string>>;

interface UniqueDataPayload {
  userName: string;
  email: string;
  domainName: string;
  companyName: string;
}

const useSignUpFrom = () => {
  const [domainFieldWidth, setDomainFieldWidth] = useState('113px');
  const tileCaseField = ['companyName', 'firstName', 'lastName'];

  const initialValues: SignUpFromInitialValues = {
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    domainName: '',
    userName: '',
    password: '',
    country: 'United States',
    terms: false,
    keepMeUpdate: true,
    int: '',
  };

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().min(6, '').required('Company Name is required'),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    int: Yup.string(),
    email: Yup.string()
      // .email('Email Address is not Valid')
      .test('is-valid', 'Email Address is Invalid', value =>
        checkIsValidEmail(value || ''),
      )
      .required('Email Address is required'),
    domainName: Yup.string()
      .required('Domain Name is required')
      .max(50, '')
      // .test(
      //   'no-numbers',
      //   'Domain Name should not contain numbers',
      //   value => !/\d/.test(value),
      // )
      .test(
        'no-special-characters',
        'Domain Name should only contain lowercase characters',
        value => /^[a-zA-Z0-9]+$/.test(value),
      ),
    userName: Yup.string()
      .required('Username is required')
      .matches(
        /^[a-zA-Z0-9]*$/, // No special characters
        ' ', // 'Username must not contain special characters',
      )
      .matches(
        /^(?=.*[A-Z])/, // At least one uppercase letter
        ' ', // 'Username must contain at least one uppercase letter',
      )
      .test('no-spaces', '', value => !/\s/.test(value)),
    password: Yup.string()
      .required('Password is required')
      .min(8, '') //Enter at least 8 characters
      .max(16, '')
      .matches(
        /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+])(?=.*[0-9]).*$/,
        '', //'Include at least one uppercase character and one special character',
      ),
    country: Yup.string().required(''),
    terms: Yup.boolean().oneOf([true], ''),
    keepMeUpdate: Yup.boolean(),
  });

  // * Here, manage manually validate case display all error at same time
  const validateUserName = (value: string, isUserNameAvailable: boolean) => {
    const errors: CustomError[] = [
      {
        message: !!isUserNameAvailable
          ? 'User Name Available'
          : 'User Name Unavailable',
        isValid: isUserNameAvailable,
      },
      {
        message: 'Include at least one uppercase letter.',
        isValid: /[A-Z]/.test(value),
      },
      {
        message: 'Username must not contain any spaces or special characters.',
        isValid: /^[a-zA-Z0-9]+$/.test(value),
      },
    ];
    return JSON.stringify(errors);
  };

  // * Here, manage manually validate case display all error at same time
  const validateDomainName = (
    value: string,
    isDomainNameAvailable: boolean,
  ) => {
    const errors: CustomError[] = [
      {
        message: !!isDomainNameAvailable
          ? 'Domain Name Available'
          : 'Domain Name Unavailable',
        isValid: !!isDomainNameAvailable,
      },
      {
        message: 'Domain Name should less than 50 latter.',
        isValid: value?.length <= 50,
      },
      {
        message: 'Domain Name excludes any spaces or special characters.',
        isValid: /^[a-zA-Z0-9]+$/.test(value),
      },
    ];
    return JSON.stringify(errors);
  };

  const [customFieldError, setCustomFieldError] = useState<StateTypeForValue>({
    userName: '',
    password: '',
  });

  // * Set custom error for form
  const setError = (name: FieldName, value: string) => {
    setCustomFieldError(preValue => ({
      ...preValue,
      [name]: value,
    }));
  };

  // * Check is given data is available or not
  const checkIsUniqueName = async (payload: Partial<UniqueDataPayload>) => {
    let data: any = '';
    await Rest.post(apiUrls.CHECK_UNIQUE_DATA, { ...payload })
      .then(response => {
        data = response;
      })
      .catch(error => {
        console.error(error);
      });
    return data;
  };

  // * on form field change
  const handleOnChange = async (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formik: FormikProps<SignUpFromInitialValues>,
  ) => {
    const { name, value }: { name: string; value: string } = event.target;
    const { setFieldValue, touched } = formik;
    if (tileCaseField.includes(name)) {
      await setFieldValue(name, value?.toCapitalize());
    } else {
      await setFieldValue(name, value);
    }

    switch (name as FieldName) {
      case 'companyName':
        if (touched.companyName) {
          // getCustomErrorMessageObj(
          //   customFieldError.companyName || '',
          //   'Company Name Required',
          // )?.isValid === false &&
          //   setRequiredErrorMessage('companyName', 'Company Name', !!value);
          +value.length < 6 &&
            setError(
              'companyName',
              JSON.stringify([
                {
                  isValid: false,
                  message: 'Enter at least 6 characters',
                },
              ]),
            );

          getCustomErrorMessageObj(
            customFieldError.companyName || '',
            'Enter at least 6 characters',
          )?.isValid === false &&
            setError(
              'companyName',
              JSON.stringify([
                {
                  isValid: +value.length > 6,
                  message: 'Enter at least 6 characters',
                },
              ]),
            );
          if (value === '') {
            setRequiredErrorMessage('companyName', 'Company Name', false);
          }
        }

        break;
      case 'firstName':
        if (touched.firstName) {
          getCustomErrorMessageObj(
            customFieldError.firstName || '',
            'First Name Required',
          )?.isValid === false &&
            setRequiredErrorMessage('firstName', 'First Name', true);
          if (value === '') {
            setRequiredErrorMessage('firstName', 'First Name', false);
          }
        }
        break;
      case 'lastName':
        if (touched.lastName) {
          getCustomErrorMessageObj(
            customFieldError.lastName || '',
            'Last Name Required',
          )?.isValid === false &&
            setRequiredErrorMessage('lastName', 'Last Name', true);
          if (value === '') {
            setRequiredErrorMessage('lastName', 'Last Name', false);
          }
        }
        break;
      case 'email':
        if (touched.email) {
          // getCustomErrorMessageObj(
          //   customFieldError.email || '',
          //   'Email Address Required',
          // )?.isValid === false &&
          //   setRequiredErrorMessage('email', 'Email Address', true);

          const isValidEmail = checkIsValidEmail(value);

          !isValidEmail &&
            setError(
              'email',
              JSON.stringify([
                {
                  isValid: false,
                  message: 'Email Address is Invalid',
                },
              ]),
            );

          getCustomErrorMessageObj(
            customFieldError.email || '',
            'Email Address is Invalid',
          )?.isValid === false &&
            setError(
              'email',
              JSON.stringify([
                {
                  isValid: isValidEmail,
                  message: isValidEmail
                    ? 'Email Address is Valid'
                    : 'Email Address is Invalid',
                },
              ]),
            );

          if (value === '') {
            setRequiredErrorMessage('email', 'Email Address', false);
          }
        }
        break;
      case 'domainName':
        if (touched.domainName) {
          getCustomErrorMessageObj(
            customFieldError.domainName || '',
            'Domain Name Required',
          )?.isValid === false &&
            value !== ' ' &&
            setRequiredErrorMessage('domainName', 'Domain Name', true);

          getCustomErrorMessageObj(
            customFieldError.domainName || '',
            'Domain Name should less than 50 latter.',
          )?.isValid === false &&
            setError(
              'domainName',
              JSON.stringify([
                {
                  isValid: value?.length <= 50,
                  message: 'Domain Name should less than 50 latter.',
                },
              ]),
            );

          getCustomErrorMessageObj(
            customFieldError.domainName || '',
            'Domain Name excludes any spaces or special characters.',
          )?.isValid === false &&
            setError(
              'domainName',
              JSON.stringify([
                {
                  isValid: /^[a-zA-Z0-9]+$/.test(value),
                  message:
                    'Domain Name excludes any spaces or special characters.',
                },
              ]),
            );
          if (value === '') {
            setRequiredErrorMessage('domainName', 'Domain Name', false);
          }
        }
        //* Prevent Space
        setFieldValue('domainName', value.replace(/\s/g, ''));
        onDomainNameChange(event);
        break;
      case 'userName':
        // * have manage with it's own
        if (value === '') {
          setRequiredErrorMessage('userName', 'User Name', false);
        }
        break;
      case 'password':
        const passwordErrorMessage = validateUserPassword(value);
        setError('password', passwordErrorMessage);
        if (value === '') {
          setRequiredErrorMessage('password', 'Password', false);
        }
        break;
    }
  };

  // * on form field blur
  const handleOnBlur = async (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formik: FormikProps<SignUpFromInitialValues>,
  ) => {
    const { name, value } = event.target;
    const { errors, values, setFieldValue } = formik;
    formik.handleBlur(event);
    setError(name as any, '');
    // * Add Validation Error
    if (value) {
      switch (name as FieldName) {
        case 'companyName':
          setUniqueErrorMessage('companyName', 'Company Name', value);
          if (+value.length < 6) {
            setError(
              'companyName',
              JSON.stringify([
                {
                  isValid: false,
                  message: 'Enter at least 6 characters',
                },
              ]),
            );
          } else if (+value.length >= 50)
            setError(
              'companyName',
              JSON.stringify([
                {
                  isValid: false,
                  message: 'Company Name should less than 50 latter',
                },
              ]),
            );
          break;
        case 'firstName':
        case 'lastName':
          if (
            !(errors.firstName && errors.lastName) &&
            values.firstName &&
            values.lastName &&
            !values.userName
          ) {
            formik.handleBlur(event);
            const firstName = `${values.firstName.toCapitalize()}`;
            const lastName = `${values.lastName.toCapitalize()}`;
            const defaultUserName = `${firstName}${lastName}`;
            setFieldValue('userName', defaultUserName);
            setError('userName', '');
          }
          break;
        case 'email':
          const isValidEmail = checkIsValidEmail(value);
          setUniqueErrorMessage('email', 'Email Address', value);
          !isValidEmail &&
            setError(
              'email',
              JSON.stringify([
                {
                  isValid: isValidEmail,
                  message: 'Email Address is Invalid',
                },
              ]),
            );
          break;
        case 'domainName':
          const checkIsDomainUnique = await checkIsUniqueName({
            domainName: value,
          });
          const domainErrorMessage = validateDomainName(
            value,
            checkIsDomainUnique?.data?.success,
          );
          setError('domainName', domainErrorMessage);
          removeCheckError(domainErrorMessage || '', 'domainName');
          break;
        case 'userName':
          removeCheckError(customFieldError.userName || '', 'userName');
          break;
        case 'password':
          removeCheckError(customFieldError.password || '', 'password');
          break;
        default:
          break;
      }
    } else {
      // * set Is required error
      switch (name as FieldName) {
        case 'companyName':
          setRequiredErrorMessage('companyName', 'Company Name', false);
          break;
        case 'firstName':
          setRequiredErrorMessage('firstName', 'First Name', false);
          break;
        case 'lastName':
          setRequiredErrorMessage('lastName', 'Last Name', false);
          break;
        case 'email':
          setRequiredErrorMessage('email', 'Email Address', false);
          break;
        case 'domainName':
          setRequiredErrorMessage('domainName', 'Domain Name', false);
          break;
        case 'userName':
          setRequiredErrorMessage('userName', 'User Name', false);
          break;
        case 'password':
          // setRequiredErrorMessage(
          //   'password',
          //   'Password',
          //   false,
          //   'Enter between 8-16 characters.',
          // );
          setRequiredErrorMessage('password', 'Password', false);
          break;
        default:
          break;
      }
    }
  };

  const handleOnFocus = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formik: FormikProps<SignUpFromInitialValues>,
  ) => {
    const { name, value } = event.target;
    switch (name as FieldName) {
      case 'password':
        if (!value) {
          const passwordErrorMessage = validateUserPassword(value);
          setError('password', passwordErrorMessage);
          // setRequiredErrorMessage('password', 'Password', false);
        }
        break;
      default:
        break;
    }
  };

  //* on Keyup
  const handleKeyUp = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    errorValue: string,
    fieldName: FieldName,
  ) => {
    switch (event.key) {
      case 'Enter':
        errorValue && removeCheckError(errorValue, fieldName);
        break;
      default:
        break;
    }
  };

  const removeCheckError = (errorValue: string, field: FieldName) => {
    const errors = JSON.parse(errorValue || '[]') || [];
    const newErrors = errors.filter((error: CustomError) => !error.isValid);
    setError(field, JSON.stringify(newErrors));
  };

  // * real time check userName with debounce
  const handleDebounceUserName = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    if (value) {
      const checkIsUnique = await checkIsUniqueName({
        userName: value,
      });
      const userNameErrorMessage = validateUserName(
        value,
        checkIsUnique?.data?.success,
      );
      setError('userName', userNameErrorMessage);
      removeCheckError(userNameErrorMessage || '', 'userName');
    } else {
      setError('userName', '');
    }
  };

  // * Check given data is available or not
  const setUniqueErrorMessage = async (
    name: FieldName,
    label: string,
    value: string,
  ) => {
    const checkIsCompanyUnique = await checkIsUniqueName({
      [name as any]: value,
    });
    if (!checkIsCompanyUnique?.data?.success) {
      const error: CustomError[] = [
        {
          isValid: false,
          message: `${label} Unavailable`,
        },
      ];
      setError(name, JSON.stringify(error));
    }

    getCustomErrorMessageObj(
      customFieldError[name] || '',
      `${label} Unavailable`,
    )?.isValid === false &&
      setError(
        name,
        JSON.stringify([
          {
            isValid: !!checkIsCompanyUnique?.data?.success,
            message: !!checkIsCompanyUnique?.data?.success
              ? `${label} Available`
              : `${label} Unavailable`,
          },
        ]),
      );
  };

  // * Check given data is available or not
  const setRequiredErrorMessage = (
    name: FieldName,
    label: string,
    value: boolean,
    customMessage?: string,
  ) => {
    const error: CustomError[] = [
      {
        isValid: value,
        message: customMessage ?? `${label} Required`,
      },
    ];
    setError(name, JSON.stringify(error));
  };

  // * domain field is with
  const onDomainNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    // setFieldValue: Function,
  ) => {
    const { value } = event.target;
    // setFieldValue(name, value);
    if (value) {
      const width = lengthChecker(value, { size: 14 });
      setDomainFieldWidth(`${width + 26}px`);
    } else {
      setDomainFieldWidth('113px');
    }
  };

  // * High light green message only id there is red message appear once
  const getCustomErrorMessageObj = (
    errorValue: string,
    messageToFind: string,
  ) => {
    const errors = JSON.parse(errorValue || '[]') || [];
    const ans = errors.find(
      (obj: CustomError) =>
        obj.message.toLowerCase() === messageToFind.toLowerCase(),
    );
    return ans;
  };

  const handleDebounceOnChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formik: FormikProps<SignUpFromInitialValues>,
  ) => {
    const { name, value } = event.target;
    const { touched } = formik;
    if (value) {
      switch (name as FieldName) {
        case 'companyName':
          if (touched.companyName) {
            setUniqueErrorMessage('companyName', 'Company Name', value);
          }
          break;
        case 'email':
          if (touched.email) {
            setUniqueErrorMessage('email', 'Email Address', value);
          }
          break;
        case 'domainName':
          if (touched.domainName) {
            setUniqueErrorMessage('domainName', 'Domain Name', value);
          }
          break;
        default:
          break;
      }
    }
  };

  return {
    initialValues,
    validationSchema,
    validateUserName,
    handleOnChange,
    customFieldError,
    handleOnBlur,
    handleDebounceUserName,
    removeCheckError,
    handleKeyUp,
    onDomainNameChange,
    domainFieldWidth,
    handleOnFocus,
    handleDebounceOnChange,
  };
};

export default useSignUpFrom;
