import { ArrowFillUp, User } from '../../../../assets/svgs';
import images from '../../../../assets/images';
import { removeLocalStorageItem } from '../../../../libs/utils';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, alpha, styled } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import clsx from 'clsx';

import CosSwitch from '../../../../components/formikForms/CosSwitch';
import { useState } from 'react';

const profileWidth = 176;

const StyledMenuItem = styled(MenuItem)({
  padding: 0,
  color: '#001F38',
  fontSize: 12,
  lineHeight: '17px',
  eight: '17px',
  width: '100%',

  '&:hover, &:focus': {
    backgroundColor: 'transparent',
    color: '#07B9EC',
    fill: {
      color: '#07B9EC',
    },
  },
  '&.MuiButton-root.Mui-disabled': {},
});

const styleMenuSwitchLabelSx = {
  '&:hover': {
    color: alpha('#001F38', 0.75),
    '& .Mui-checked': {
      '.MuiSwitch-thumb': {
        backgroundColor: '#ffffff',
        opacity: 0.75,
      },
    },
    '& .MuiSwitch-switchBase:not(.Mui-checked)': {
      '.MuiSwitch-thumb': {
        backgroundColor: '#001F38',
      },
    },
  },
};

const styleMenuSx = {
  '& .MuiPaper-root': {
    borderRadius: '6px',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    width: profileWidth,
    maxWidth: profileWidth,
    boxShadow: '1px 2px 7px #001F3840',
    background: '#EDEEEF',
    marginLeft: '8.4px',
    marginTop: '-1px',

    '& .MuiMenu-list': {
      padding: '5px 10px 4px 10px',
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {},
      '&:active': {},
    },
  },
};

const AdminProfile = () => {
  const navigate = useNavigate();
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);

  const handleLogout = (callback: Function) => {
    callback();
    removeLocalStorageItem('cosUserData');
    setTimeout(() => {
      navigate('/login');
    }, 500);
  };

  return (
    <div>
      <div>
        <PopupState variant="popover" popupId="admin-profile-popup">
          {popupState => {
            return (
              <>
                <Box
                  component="section"
                  sx={{
                    width: profileWidth,
                  }}
                  className={clsx(
                    'flex h-[47px] items-center rounded-[4px] bg-white-whale-500 p-[6px]',
                    { 'rounded-b-none': popupState.isOpen },
                  )}
                >
                  <User className="size-8" />
                  <div className="flex flex-grow items-center justify-between">
                    <div className="flex-grow pl-[7.6px]">
                      <Typography className="-mt-[3px] mb-[2px] text-12/17 text-dark-blue-900">
                        Brignac, Chad
                      </Typography>
                      <Typography className="text-10/10 text-artist-blue-900">
                        Chad Built Homes
                      </Typography>
                    </div>
                    <div className="mt-1 flex flex-col items-center justify-center">
                      <div className="flex size-6 items-center justify-center rounded-full border border-solid border-dark-red-500">
                        <span className="text-10/22 text-dark-red-500">0%</span>
                      </div>
                      <img
                        src={images.downArrow}
                        width={'10px'}
                        className={clsx(
                          'base-animation  cursor-pointer transition-transform',
                          {
                            'rotate-180': popupState.isOpen,
                          },
                        )}
                        {...bindTrigger(popupState)}
                      />
                    </div>
                  </div>
                </Box>
                <Menu {...bindMenu(popupState)} sx={styleMenuSx}>
                  <StyledMenuItem disabled sx={styleMenuSwitchLabelSx}>
                    <span className="flex-grow">Admin Mode</span>
                    <CosSwitch defaultChecked />
                  </StyledMenuItem>
                  <StyledMenuItem sx={styleMenuSwitchLabelSx}>
                    <span className="flex-grow">Tool Tip Hovers</span>
                    <CosSwitch defaultChecked />
                  </StyledMenuItem>
                  <StyledMenuItem
                    // onClick={() => setIsLanguageOpen(preValue => !preValue)}
                    onClick={popupState.close}
                  >
                    <span className="flex-grow">Language</span>
                    <div className="flex items-center gap-1">
                      <div className="relative">
                        <span>English</span>
                        {/* <ArrowFillUp /> */}
                      </div>
                      {/* {isLanguageOpen && (
                        <Box
                          sx={{
                            boxShadow: '0px 0px 6px #A8B1B8',
                            position: 'absolute',
                            borderRadius: '3px',
                            backgroundColor: '#EDEEEF',
                          }}
                        >
                          <p className="m-0">Spanish</p>
                          <p className="m-0">French</p>
                        </Box>
                      )} */}
                    </div>
                  </StyledMenuItem>
                  <StyledMenuItem onClick={popupState.close}>
                    Settings
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={() => handleLogout(popupState.close)}
                    className="hover:text-dark-red-500"
                  >
                    Logout
                  </StyledMenuItem>
                </Menu>
              </>
            );
          }}
        </PopupState>
      </div>
    </div>
  );
};
export default AdminProfile;
