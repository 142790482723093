const images = {
  schedulerOpsLogo: require('./images/SchedulrOps_Primary-logo.png'),
  schedulerOpsLogo_x2: require('./images/SchedulrOps_Primary-logo@2x.png'),
  bottomLogo: require('./images/bottom_logo.png'),
  bottomLogo_x2: require('./images/bottom_logo@2x.png'),
  constructionsOpsBackGround: require('./images/ConstructionsOps-Photoshoot.png'),
  constructionsOpsBackGround_x2: require('./images/ConstructionsOps-Photoshoot@2x.png'),
  SchedulrOpsTertiarylogo: require('./images/SchedulrOps_Tertiary-logo.png'),
  SchedulrOpsTertiarylogo_x2: require('./images/SchedulrOps_Tertiary-logo@2x.png'),
  passwordKey: require('./images/signupkey.png'),
  downIcon2: require('./images/down-icon2.png'),
  usFlag: require('./images/US-flag.png'),
  usFlag_x2: require('./images/US-flag@2x.png'),
  upArrow: require('./images/up-icon@2x.png'),
  downArrow: require('./images/down-icon@2x.png'),

  // GIGS
  yellowGif: require('./gifs/yellow-large-loader.gif'),
};

export default images;
