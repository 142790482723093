import { styled } from '@mui/material';
import { constant } from '../../../../libs/constant';

export const AppBarLeftSideBarHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  height: constant.appBarHeight + 'px',
  width: constant.drawerWidth + 'px',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default AppBarLeftSideBarHeader;
