import { ChangeEvent, useState } from 'react';
import * as Yup from 'yup';
import {
  validatePassword,
  validateEmailOrUsername,
} from '../../../../libs/helper';
import { useSearchParams } from 'react-router-dom';
import { FormikProps } from 'formik';
import { CustomError } from '../../../../libs/interface';

interface InitialValues {
  email: string;
  password: string;
  isRememberMe: boolean;
}

const useLoginFrom = () => {
  const [searchParams] = useSearchParams();
  const [initialValues, setInitialValues] = useState<InitialValues>({
    email: searchParams.get('username') || '',
    password: '',
    isRememberMe: false,
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(''),
    password: Yup.string().required(''),
    isRememberMe: Yup.boolean(),
  });

  type FieldName = keyof typeof initialValues;
  type StateTypeForValue = Partial<Record<FieldName, string>>;

  const [customFieldError, setCustomFieldError] = useState<StateTypeForValue>({
    email: '',
    password: '',
  });

  const setError = (name: FieldName, value: string) => {
    setCustomFieldError(preValue => ({
      ...preValue,
      [name]: value,
    }));
  };

  const handleOnChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formik: FormikProps<InitialValues>,
  ) => {
    const { name, value } = event.target;
    const { setFieldValue, touched } = formik;
    setFieldValue(name, value);
    switch (name as FieldName) {
      case 'email':
        if (touched.email) {
          getCustomErrorMessageObj(
            customFieldError.email || '',
            'User Name / Email Required',
          )?.isValid === false &&
            setRequiredErrorMessage('email', 'User Name / Email', true);
          if (value === '') {
            setRequiredErrorMessage('email', 'User Name / Email', false);
          }
        }
        break;
      case 'password':
        if (touched.email) {
          getCustomErrorMessageObj(
            customFieldError.password || '',
            'Password Required',
          )?.isValid === false &&
            setRequiredErrorMessage('password', 'Password', true);
          if (value === '') {
            setRequiredErrorMessage('password', 'Password', false);
          }
        }
        break;
      default:
        break;
    }
  };

  // * on form field blur
  const handleOnBlur = async (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formik: FormikProps<InitialValues>,
  ) => {
    const { name, value } = event.target;
    formik.handleBlur(event);
    setError(name as any, '');
    if (!value) {
      switch (name as FieldName) {
        case 'email':
          setRequiredErrorMessage('email', 'User Name / Email', false);
          break;
        case 'password':
          setRequiredErrorMessage('password', 'Password', false);
          break;
        default:
          break;
      }
    }
  };

  // * Check given data is available or not
  const setRequiredErrorMessage = (
    name: FieldName,
    label: string,
    value: boolean,
    customMessage?: string,
  ) => {
    const error: CustomError[] = [
      {
        isValid: value,
        message: customMessage ?? `${label} Required`,
      },
    ];
    setError(name, JSON.stringify(error));
  };

  // * High light green message only id there is red message appear once
  const getCustomErrorMessageObj = (
    errorValue: string,
    messageToFind: string,
  ) => {
    const errors = JSON.parse(errorValue || '[]') || [];
    const ans = errors.find(
      (obj: CustomError) =>
        obj.message.toLowerCase() === messageToFind.toLowerCase(),
    );
    return ans;
  };

  return {
    initialValues,
    validationSchema,
    handleOnChange,
    customFieldError,
    setInitialValues,
    handleOnBlur,
  };
};
export default useLoginFrom;
