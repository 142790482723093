import React from 'react';
import CosBasDialog, { CosBaseDialogProps } from './CosBasDialog';
import clsx from 'clsx';
import { Box } from '@mui/material';
import { constant } from '../../libs/constant';

interface Props extends CosBaseDialogProps {
  onConfirm?: () => void;
  confirmText?: string;
}

const CosMessageDialog: React.FC<Props> = ({
  children,
  headingClass,
  bodyClass,
  onConfirm,
  confirmText,
  ...rest
}) => {
  return (
    <CosBasDialog
      headingClass={clsx(
        'flex justify-center font-semibold text-artist-blue-900 text-18/22 pt-2.5',
        headingClass,
      )}
      className="rounded-messageModal"
      bodyClass={clsx('min-w-[350px] max-w-[425px]', bodyClass)}
      actionClass="justify-center border-0 border-t-[1px] border-solid border-artist-blue-900 p-0 h-[44px] bg-white"
      dialogActions={
        <Box
          onClick={onConfirm && onConfirm}
          className="cursor-pointer text-17/20 -tracking-0.41 text-artist-blue-900"
          sx={{
            '&': {
              transition: 'zoom 0.3s ease-in-out',
            },
            '&:hover': {
              zoom: 1.1,
              fontWeight: 600,
            },
          }}
          role="button"
        >
          {confirmText || 'OK'}
        </Box>
      }
      sx={{
        '.MuiPaper-root': {
          borderRadius: constant.messageModalRadius + 'px',
          background: '#EDEEEF',
          boxShadow: '0px 0px 12px #A8B1B8',
        },
      }}
      {...rest}
    >
      <Box className="text-center text-13/18">{children}</Box>
    </CosBasDialog>
  );
};

export default CosMessageDialog;
