import images from '../../../assets/images';
import LanguageDropdown from './LanguageDropdown';
import { SchedulrOpsPrimarylogo } from '../../../assets/svgs';

const Header = () => {
  return (
    <header
      className="lg-fixed-header justify-between border-0 border-b-8 border-solid border-black-Pearl-900 sm:flex  sm:h-[111.5px] xl:text-center"
      style={{
        boxShadow: 'inset 0px 3px 6px #00000029, 0px 3px 6px #00000029',
      }}
    >
      <div className="items-end justify-start px-[50px] pt-2.5 sm:flex sm:pb-2.5">
        <SchedulrOpsPrimarylogo className="Sizingforlogo" />
      </div>
      <LanguageDropdown />
    </header>
  );
};

export default Header;
