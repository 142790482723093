import styled from '@emotion/styled';
import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';

interface CustomTooltipProps extends TooltipProps {
  dynamiccolor?: string;
  dynamicbackground?: string;
  dynamicdecoration?: string;
  theme?: 'dark' | 'light';
}

const BaseTooltip = styled(({ className, ...props }: CustomTooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ dynamiccolor, theme, dynamicbackground, dynamicdecoration }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color:
      theme === 'dark'
        ? '#001F38'
        : theme === 'light'
          ? '#ffffff'
          : dynamicbackground,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    background:
      theme === 'dark'
        ? '#001F38'
        : theme === 'light'
          ? '#ffffff'
          : dynamicbackground,
    marginLeft: '0',
    fontSize: '11px',
    boxShadow: '0px 0px 3px #A8B1B8',
    lineHeight: '17px',
    color:
      theme === 'dark'
        ? '#ffffff'
        : theme === 'light'
          ? '#001F38'
          : dynamiccolor,
    '&:hover': {
      textDecoration: dynamicdecoration,
    },
    height: '25px',
    textAlign: 'center',
  },
}));

const CosTooltip: React.FC<CustomTooltipProps> = ({
  dynamiccolor,
  dynamicbackground,
  dynamicdecoration,
  theme = 'dark',
  ...rest
}) => {
  return (
    <BaseTooltip
      arrow
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -8],
              },
            },
          ],
        },
      }}
      dynamiccolor={dynamiccolor}
      dynamicbackground={dynamicbackground}
      dynamicdecoration={dynamicdecoration}
      theme={theme}
      {...rest}
    />
  );
};
export default CosTooltip;
