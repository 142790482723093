import { Box } from '@mui/material';
import CosSwitch from '../components/formikForms/CosSwitch';

const StandardSwitchPage = () => (
  <div className="space-y-10">
    <div className="space-x-4">
      <p>Normal</p>

      <CosSwitch defaultChecked={false} />
      <CosSwitch defaultChecked={true} />
    </div>

    <div className="space-x-4">
      <p>Disable</p>
      <CosSwitch defaultChecked={false} disabled />
      <CosSwitch defaultChecked={true} disabled />
    </div>

    <div className="space-x-4">
      <p>Dark</p>
      <div className="inline bg-dark-blue-900 p-2">
        <CosSwitch defaultChecked={false} version="dark" />
      </div>
      <div className="inline bg-dark-blue-900 p-2">
        <CosSwitch defaultChecked={true} version="dark" />
      </div>
    </div>
  </div>
);
export default StandardSwitchPage;
