import { Box, BoxProps, IconButton } from '@mui/material';
import React from 'react';
import { CloseOutlinedIcon } from '../../assets/svgs';

const CloseIcon: React.FC<BoxProps> = ({ ...rest }) => {
  return (
    <Box {...rest}>
      <IconButton
        tabIndex={-1}
        // * Temp : add .25 opacity adn disabled
        disabled
        className="h-6 w-6 border-[0.5px] border-solid border-[#ffb92c99] text-buddha-love-800 opacity-25 hover:bg-buddha-love-800 hover:text-white"
      >
        <CloseOutlinedIcon style={{ width: 18, height: 18 }} />
      </IconButton>
    </Box>
  );
};

export default CloseIcon;
