import { Radio, RadioGroupProps, alpha, styled } from '@mui/material';
import CosLabel, { CosLabelProps } from './CosLabel';

import clsx from 'clsx';

const size = 13;
const BpIcon = styled('span')(({}) => ({
  borderRadius: 3,
  width: size,
  height: size,
  border: '1px solid',
  borderEndStartRadius: '5px',
  borderStartEndRadius: '5px',
  borderEndEndRadius: '5px',
  borderStartStartRadius: '5px',
  borderColor: alpha('#07B9EC', 0.25),

  'input:hover ~ &': {
    backgroundColor: '#07B9EC',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
}));
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#07B9EC',
  border: '0px solid #07B9EC',

  '&::before': {
    display: 'block',
    width: size,
    height: size,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: alpha('#07B9EC', 0.8),
  },
});
export interface CosRadioButtonProps extends RadioGroupProps {
  label?: React.ReactNode | string;
  isRequired?: boolean;
  labelProps?: Omit<CosLabelProps, 'children'>;
  error?: boolean;
}

const CosRadioBox = (props: CosRadioButtonProps) => {
  const {
    label,
    error,
    className,
    isRequired,
    sx,
    labelProps,
    ...checkboxProps
  } = props;
  return (
    <>
      <div className="flex items-center">
        <Radio
          className={clsx('p-0 pr-2', className)}
          checkedIcon={<BpCheckedIcon />}
          icon={<BpIcon />}
        />
        {label && (
          <CosLabel isRequired={isRequired} {...labelProps}>
            {label}
          </CosLabel>
        )}
      </div>
    </>
  );
};
export default CosRadioBox;
