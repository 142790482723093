import React, { useState } from 'react';
import { Field, FieldAttributes, FieldProps } from 'formik';
import { ErrorMessage } from './ui';
import clsx from 'clsx';
import CosPasswordInput, { CosPasswordInputProps } from './ui/CosPasswordInput';
import { CosFormErrorAlert } from '..';
import { CustomError } from '../../libs/interface';
import { CosAlertProps } from '../alert/CosFormErrorAlert';

interface FormInputProps extends CosPasswordInputProps {
  name: string;
  label?: string;
  autoComplete?: string;
  fieldProps?: Omit<FieldAttributes<any>, 'name'>;
  hideDefaultError?: boolean;
  alertProps?: Omit<CosAlertProps, 'children'>;
}

const FormPassword: React.FC<FormInputProps> = ({
  name,
  autoComplete,
  label,
  className,
  fieldProps,
  hideDefaultError,
  value,
  onBlur,
  alertProps,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Field name={name} {...fieldProps}>
      {({
        field: { onBlur: formikOnBlur, value: fieldValue, ...fieldRest },
        meta,
      }: FieldProps) => {
        return (
          <div className={clsx('mb-4', className)}>
            <CosPasswordInput
              className={clsx('!mb-0')}
              label={label}
              onFocus={() => setIsFocused(true)}
              onBlur={event => {
                formikOnBlur(event);
                setIsFocused(false);
                onBlur && onBlur(event);
              }}
              {...fieldRest}
              name={name}
              error={!!(meta.touched && meta.error)}
              value={value || fieldValue}
              {...rest}
            />

            {!hideDefaultError &&
              meta.touched &&
              meta.error &&
              meta.error !== ' ' &&
              !isFocused && (
                <CosFormErrorAlert
                  severity="error"
                  className="mt-2"
                  icon={true}
                  {...alertProps}
                >
                  {meta.error}
                </CosFormErrorAlert>
              )}
          </div>
        );
      }}
    </Field>
  );
};

export default FormPassword;
