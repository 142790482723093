import { CustomError } from './interface';

export type ObjectType = Record<string | number, any>;

/**
 * Checks if two objects are equal by comparing their JSON representations.
 * @param {Object} obj1 - The first object to compare.
 * @param {Object} obj2 - The second object to compare.
 * @returns {boolean} - True if both objects are equal, otherwise false.
 */
export const areObjectsEqualJSON = (
  obj1: ObjectType,
  obj2: ObjectType,
): boolean => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export function setToLocalStringNumber(value: string | number): string {
  const formatter = new Intl.NumberFormat('en', { notation: 'standard' });
  return formatter.format(+value);
}

/**
 *  Remove null or empty value
 */
export const sanitizeObject = (valueObj: ObjectType): ObjectType => {
  return Object.fromEntries(
    Object.entries(valueObj).filter(
      ([_key, value]) => value !== '' && value !== null && value !== undefined,
    ),
  );
};

/**
 * Checks if any key in the given object has a truthy value.
 * @param obj - The object to check.
 * @returns {boolean} - True if any key has a truthy value, otherwise false.
 */
export function hasAnyCustomError(obj: ObjectType): boolean {
  return Object.values(obj).some((value: string) => {
    const errors: CustomError[] = JSON.parse(value || '[]');
    if (Array.isArray(errors)) {
      return errors.some(error => !error.isValid);
    }
  });
}

/**
 * Checks str is isCaseSensitive or not.
 * @Rule 1: No space
 * @Rule 2: At least one character should be capital
 * @Rule 3: Should not contain special characters
 */
export function isCaseSensitive(text: string): boolean {
  return !/\s/.test(text) && /[A-Z]/.test(text) && /^[a-zA-Z0-9]+$/.test(text);
}

// Function to store data in local storage
export function setLocalStorageItem<T>(key: string, value: T | any): boolean {
  try {
    localStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (error) {
    console.error('Error setting local storage item:', error);
    return false;
  }
}

// Function to retrieve data from local storage
export function getLocalStorageItem<T>(key: string): T | null {
  try {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error('Error getting local storage item:', error);
    return null;
  }
}

// Function to remove data from local storage
export function removeLocalStorageItem(key: string): boolean {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (error) {
    console.error('Error removing local storage item:', error);
    return false;
  }
}
export function cleanAllLocalStorage(): void {
  localStorage.clear();
}

export function checkIsValidEmail(value: string): boolean {
  const regex =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
  return regex.test(value);
}

export function isDomainNameAvailable(): boolean {
  if (process.env.NODE_ENV === 'production') {
    const pattern = /^https:\/\/[a-z0-9-]+\.sch-ops\.com$/;
    return pattern.test(location.origin);
  } else {
    return location.origin.split('.').length === 2;
  }
}
