import React from 'react';
import ContactUsFrom from '../modules/contactUs/ContactUsFrom';
import AuthLayout from '../layout/auth/AuthLayout';

const ContactPage = () => {
  return (
    <AuthLayout>
      <ContactUsFrom />
    </AuthLayout>
  );
};

export default ContactPage;
