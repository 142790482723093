import React, { useEffect, useState } from 'react';

export interface CountProps {
  startFrom: string;
  upto: string;
  duration?: string;
  className?: string;
  afterCompleted?: Function;
  pauseAt96?: boolean; // New prop for pausing at 95
}

const Counter: React.FC<CountProps> = props => {
  const {
    upto,
    duration = '2',
    startFrom,
    className,
    afterCompleted,
    pauseAt96,
  } = props;

  const [count, setCount] = useState(startFrom);
  const [paused, setPaused] = useState(false); // State for pausing

  useEffect(() => {
    let start = parseInt(startFrom.substring(0, 2)); // Start from provided value
    const end = parseInt(upto.substring(0, 3));
    if (start === end) return;

    let totalMilSecDur = parseInt(duration.toString());
    let incrementTime = (totalMilSecDur / end) * 10000;

    let timer = setInterval(() => {
      if (start === 96 && pauseAt96) {
        setPaused(true);
        clearInterval(timer);
        return;
      }
      if (paused) return; // If paused, do not increment
      start += 2;
      setCount(String(start) + upto.substring(3));
      if (start === end) {
        clearInterval(timer);
        setTimeout(() => {
          afterCompleted && afterCompleted();
        }, 1000);
      }
    }, incrementTime);

    return () => clearInterval(timer); // Cleanup function
  }, [upto, duration, startFrom, afterCompleted, pauseAt96, paused]);

  return <span className={className}>{count}</span>;
};

export default Counter;
