import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { getLocalStorageItem } from '../../libs/utils';

export default function ProtectedRouter({
  component: Component,
  ...rest
}: any) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);

  useEffect(() => {
    const userData = getLocalStorageItem('cosUserData');
    if (userData) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  return !isAuthenticated ? <Navigate to="/login" /> : <Outlet />;
}
