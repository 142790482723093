import React, {
  HTMLProps,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  InputBase,
  InputBaseProps,
  SxProps,
  Theme,
  styled,
} from '@mui/material';
import CosLabel, { CosLabelProps } from './CosLabel';
import clsx from 'clsx';
import { constant } from '../../../libs/constant';
import { useDebounceValue } from '../../../libs/hooks';

export interface CosInputProps extends InputBaseProps {
  label?: string;
  name?: string;
  type?: 'text' | 'password';
  error?: boolean;
  startIcon?: ReactNode | undefined;
  isRequired?: boolean;
  labelProps?: Omit<CosLabelProps, 'children'>;
  children?: ReactNode;
  inputWrapperClass?: HTMLProps<HTMLElement>['className'];
  inputSx?: SxProps<Theme>;
  tooltipTitle?: string;
  hideKeeperSuggestion?: boolean;
  debounceCallback?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  debounceTime?: number;
}

const CustomInput = styled(InputBase, {
  shouldForwardProp: prop => prop !== 'startIcon' && prop !== 'inputSx',
})<{
  inputSx?: SxProps<Theme>;
  startIcon?: ReactNode | undefined;
}>(({ theme, inputSx, startIcon }) => ({
  width: '100%',

  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    height: '34px',
    position: 'relative',
    color: '#001F38',
    fontWeight: '600',
    borderRadius: '3px',
    border: '1.5px solid #FCBD3D',
    backgroundColor: '#ffffff',
    fontSize: '14px',
    lineHeight: '18px',
    width: '100%',
    padding: startIcon
      ? `0 ${constant.inputXSpacing}px 0 43px`
      : `0 ${constant.inputXSpacing}px`,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      '&::placeholder': {
        opacity: 0.75,
      },
    },
    '&:hover': {
      '&::placeholder': {
        opacity: 0.75,
      },
    },
    '&::placeholder': {
      color: '#001f38',
      fontWeight: '400',
      opacity: 1,
      transition: 'opacity 0.3s ease',
    },
    ...(inputSx && inputSx),
  },
}));

const CosInput: React.FC<CosInputProps> = ({
  name,
  label,
  placeholder,
  error,
  startIcon,
  type = 'text',
  className,
  size = 'default',
  isRequired = true,
  labelProps,
  inputSx,
  children, //* For UI purposes
  inputWrapperClass,
  tooltipTitle,
  inputProps,
  hideKeeperSuggestion = true,
  onChange,
  debounceCallback,
  debounceTime = 500,
  ...rest
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEvent(event);
    onChange && onChange(event);
  };

  // * Start : Manage Debounce function
  const [event, setEvent] = useState<
    React.ChangeEvent<HTMLInputElement> | undefined
  >();

  const debouncedValue = !!debounceCallback
    ? useDebounceValue(event?.target.value, debounceTime)
    : '';

  const callBack = useCallback(async () => {
    if (event?.target.value) {
      debounceCallback && debounceCallback(event);
    }
  }, [debouncedValue]);

  useEffect(() => {
    callBack();
  }, [debouncedValue, callBack]); //* End : Manage Debounce function

  return (
    <div className={clsx('mb-4 inline-block w-full', className)}>
      {label && (
        <div className="flex">
          <CosLabel
            isRequired={isRequired}
            {...labelProps}
            tooltipTitle={tooltipTitle}
          >
            {label}
          </CosLabel>
        </div>
      )}
      <div className={clsx('relative', inputWrapperClass)}>
        {startIcon && (
          <div className="absolute z-10 inline-flex h-full w-11 items-center justify-center">
            {startIcon}
          </div>
        )}
        <CustomInput
          inputSx={inputSx}
          startIcon={startIcon}
          type={type}
          placeholder={placeholder}
          error={error}
          id={name + ' user_field'}
          onChange={handleChange}
          name={name}
          className={clsx({
            inputWithIcon: startIcon,
          })}
          //* remove autofillF
          autoComplete="new-password"
          inputProps={{
            // className: hideKeeperSuggestion ? 'keeper-ignore' : '',
            className: 'keeper-ignore',
            spellCheck: 'false',
            ...inputProps,
          }}
          {...rest}
        />
        {children && children}
      </div>
    </div>
  );
};

export default CosInput;
