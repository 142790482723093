import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import clsx from 'clsx';

const BaseButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  border: '1px solid',
  lineHeight: 1.5,
  color: '#FFB92C',
  borderColor: 'currentColor',
  height: 41,
  paddingLeft: 20,
  paddingRight: 20,
  // padding: '3.5px 12px',
  '&:hover, &:focus': {
    color: '#ffffff',
    backgroundColor: '#FFB92C',
    borderColor: '#FFB92C',
  },
  '&.MuiButton-root.Mui-disabled': {
    color: '#FFB92C',
    opacity: 0.25,
  },
});

interface CosButtonProps extends ButtonProps {
  children: React.ReactNode;
  isLoading?: boolean;
}

const CosButton: React.FC<CosButtonProps> = props => {
  const { children, className, isLoading, onClick, ...rest } = props;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isLoading) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <BaseButton
      disableRipple
      disableElevation
      disableFocusRipple
      className={clsx(
        isLoading ? 'cursor-progress' : 'cursor-pointer',
        className,
      )}
      onClick={handleClick}
      {...rest}
    >
      {children}
    </BaseButton>
  );
};

export default CosButton;
