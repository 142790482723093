import React, { useState, useEffect } from 'react';
import { alpha, styled } from '@mui/material/styles';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import CosLabel, { CosLabelProps } from './CosLabel';
import clsx from 'clsx';

const size = 13;

const BpIcon = styled('span', {
  shouldForwardProp: prop =>
    prop !== 'isChecked' && prop !== 'isFocused' && prop !== 'isValid',
})<{
  isFocused?: boolean;
  isValid?: boolean; // Add isFocused prop here
}>(({ theme, isFocused, isValid }) => ({
  borderRadius: 3,
  width: size,
  height: size,
  border: '1px solid',
  borderColor: alpha('#07B9EC', 0.25),
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
  '@media (min-width: 768px)': {
    'input:hover ~ &': {
      backgroundColor: '#07B9EC',
    },
  },
  ...(isFocused && {
    backgroundColor: alpha('#07B9EC', 0.75),
  }),
}));

const BpCheckedIcon = styled(BpIcon, {
  shouldForwardProp: prop => prop !== 'isChecked',
})<{
  isChecked?: boolean;
}>(({ theme, isFocused }) => ({
  backgroundColor: '#07B9EC',
  border: '0px solid #07B9EC',

  '&::before': {
    display: 'block',
    width: size,
    height: size,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  '@media (min-width: 768px)': {
    'input:hover ~ &': {
      backgroundColor: '#07B9EC',
    },
  },
  ...(isFocused && {
    backgroundColor: alpha('#07B9EC', 0.75),
  }),
}));

export interface CosCheckboxProps extends CheckboxProps {
  isValid?: boolean;
  label?: React.ReactNode | string;
  isRequired?: boolean;
  labelProps?: Omit<CosLabelProps, 'children'>;
  error?: boolean;
}

const CosCheckbox = (props: CosCheckboxProps) => {
  // TODO :  Manage error
  const {
    isValid,
    label,
    error,
    className,
    isRequired,
    sx,
    labelProps,
    ...checkboxProps
  } = props;

  const handleKeyDown: React.KeyboardEventHandler<
    HTMLButtonElement
  > = event => {
    if (event.key === ' ') {
      // * Stop all space-bar keyboard interaction
      event.preventDefault();
      event.stopPropagation();
    }
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      const target = event.target as HTMLInputElement;
      target.click();
    }
  };

  const [isFocused, setIsFocused] = useState(false);

  // TODO:  change data type
  const handleKeyCapture = (event: any) => {
    if (event.key === 'Tab') {
      setIsFocused(true);
    }
  };

  // TODO:  change data type
  const handleDownCapture = (event: any) => {
    setIsFocused(false);
  };

  return (
    <div className="flex items-center">
      <Checkbox
        sx={{
          '&:hover': { bgcolor: 'transparent' },
          ...sx,
        }}
        disableRipple
        className={clsx('p-0', className)}
        checkedIcon={<BpCheckedIcon isFocused={isFocused} />}
        onKeyUpCapture={event => handleKeyCapture(event)}
        onBlurCapture={event => handleDownCapture(event)}
        // onMouseEnter={event => setIsFocused(true)}
        // onMouseLeave={event => setIsFocused(false)}
        icon={<BpIcon isFocused={isFocused} isValid={isValid} />}
        onKeyDown={handleKeyDown} // Handle Enter key press
        {...checkboxProps}
      />
      {label && (
        <CosLabel
          isRequired={isRequired}
          {...labelProps}
          className={clsx('ml-2', labelProps?.className)}
        >
          {label}
        </CosLabel>
      )}
    </div>
  );
};

export default CosCheckbox;
