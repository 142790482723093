import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import './authLayout.css';
interface Props {
  children: React.ReactNode;
}

// const heroStyle: React.CSSProperties = {
//   background: `url('${images.constructionsOpsBackGround}') no-repeat`,
//   backgroundSize: 'cover',
//   backgroundPosition: 'top left',
//   position: 'relative',
//   zIndex: -10,
// };

const AuthLayout: React.FC<Props> = ({ children }) => {
  return (
    <main className="hero-bg min-h-screen">
      <Header />
      <section className="overflow-auto lg:h-[calc(100vh-175px)]">
        {children}
      </section>
      <Footer className="mt-auto" />
    </main>
  );
};

export default AuthLayout;
