import React, { useState } from 'react';
import { CosButton } from '../../components';
import { FormInput, FormPassword } from '../../components/formikForms';
import { Form, Formik } from 'formik';
import useContactUs from './useContactUs';
import { CosLabel } from '../../components/formikForms/ui';
import { QuestionRed, QuestionRedDark } from '../../assets/svgs';
import { Typography } from '@mui/material';
import { CosMessageDialog } from '../../components/dialogBox';
import { useLocation } from 'react-router-dom';
import '../auth/login/components/LoginForm.css';
import { useSearchParams } from 'react-router-dom';

const ContactUsFrom = () => {
  const [searchParams] = useSearchParams();
  const data = JSON.parse(searchParams.get('data') || '{}');
  const { error, companyName } = data;

  const { initialValues, validationSchema, handleOnSubmit, showMessageModal } =
    useContactUs();

  const smallInputStyle = {
    background: '#EAEBED',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0px',
    height: '34px',
  };

  return (
    <div className="h-full">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={value => handleOnSubmit()}
      >
        {() => {
          return (
            <Form className="h-full">
              <section className="row flex justify-center">
                <div className="positionsetting">
                  <div className="logo_space"></div>
                  <div className="error_space text-center"></div>
                  <div className="flex items-center justify-center">
                    <CosLabel
                      tooltipTitle={error ? 'Texthere' : ''}
                      isRequired={false}
                      icon={<QuestionRed />}
                      hoverIcon={<QuestionRedDark />}
                    >
                      <p className="m-0 h-[21px] text-16/20 font-semibold text-error-900">
                        {error ? error : ''}
                      </p>
                    </CosLabel>
                  </div>
                  <Typography
                    variant="h6"
                    className="lastName m-0 mb-1 h-[29px] text-center font-controller text-24/29 font-semibold capitalize -tracking-0.43 text-dark-blue-900"
                  >
                    {companyName}
                  </Typography>
                  <p className="text-center font-controller text-14/18 font-semibold  tracking-normal text-buddha-love-800">
                    Enhanced Optimization
                  </p>
                  <section className="m-auto w-[460px]">
                    <div className={'h-[73px]'}>
                      <div className="flex gap-3">
                        <FormInput
                          name="firstName"
                          placeholder="FIRST NAME"
                          className="flex-1"
                          inputSx={smallInputStyle}
                          alertProps={{
                            className: '!mt-1',
                          }}
                        />
                        <FormInput
                          name="lastName"
                          placeholder="LAST NAME"
                          className="flex-1"
                          inputSx={smallInputStyle}
                          alertProps={{
                            className: '!mt-1',
                          }}
                        />
                      </div>
                    </div>
                    <div className={'h-[73px]'}>
                      <FormPassword
                        name="userName"
                        placeholder="USERNAME"
                        className="!mb-2"
                        inputSx={smallInputStyle}
                        alertProps={{
                          className: '!mt-1',
                        }}
                      />
                    </div>
                    <div className={'h-[26px]'}></div>
                    <div className="mb-8 mt-4">
                      <CosButton type="submit" fullWidth>
                        Contact
                      </CosButton>
                    </div>
                  </section>
                </div>
              </section>
            </Form>
          );
        }}
      </Formik>
      {showMessageModal && (
        <CosMessageDialog
          open={showMessageModal}
          heading={'Contact Administrator'}
          headingClass="pb-0"
          bodyClass="py-0 my-[49px]"
          onConfirm={() => window.close()}
          confirmText="Close Browser"
        >
          <p className="m-0">
            You will get the administrator call and email to assist you very
            soon. Please keep in touch
          </p>
        </CosMessageDialog>
      )}
    </div>
  );
};

export default ContactUsFrom;
